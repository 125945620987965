import { useState, useCallback } from "react";
import { registerOnServer } from '../services/registerOnServer';
import useUserState from './useUserState';

export const useRegistration = () => {
    const [loading, setLoading] = useState(false);
    const { updateUserData } = useUserState();

    const registerUser = useCallback(async ({ name, email, password, agreeToTerms }) => {
        setLoading(true);

        try {
            const response = await registerOnServer({ name, email, password, agreeToTerms });

            if ((response.status === 200 || response.status === 201) && !response.data.error) {
                updateUserData(response.data.user);
                return { success: true, status: response.status, user: response.data.user };
            } else {
                return { success: false, error: response.data.error };
            }
        } finally {
            setLoading(false);
        }
    }, [updateUserData]);

    return {
        registerUser,
        loading
    };
};