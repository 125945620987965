import React from 'react';
import './Confirmation.css';
import '../../styles/inputStyles.css';
import SubmitButton from '../common/SubmitButton';
import appConfig from '../../static/json/appConfig.json';
import '../../styles/inputStyles.css';
import { useUser } from '../../context/UserContext';

const UnsupportedCountryNotice = ({ onBack }) => {

    const { user } = useUser();
    const commonText = appConfig[user.language]["common"];
    return (
        <div className="overlay bottom-div parent-centered-div">
            <div className="centered-div">
                <div>
                    <div style={{ position: 'relative', textAlign: 'center' }}>
                        <div style={{ fontSize: '72px' }}>⚠️</div>
                        <p className="titleText" style={{ marginTop: '10px' }}>
                            {commonText["We apologize, but we are currently unable to process bank cards from your country."]}
                            {commonText["Use FREE subscription."]}
                        </p>
                    </div>
                    <SubmitButton
                        text={commonText["Acknowledge and Return"]}
                        onSubmit={onBack}
                    />
                </div>
            </div>
        </div>
    );
};

export default UnsupportedCountryNotice;