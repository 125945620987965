import React, { useState } from 'react';
import '../../styles/inputStyles.css'; // Правильный импорт общего CSS файла

import appConfig from '../../static/json/appConfig.json';
import CustomForm from '../common/CustomForm';
import { componentInstaller } from '../../utils/componentInstaller';
import useFocusManagement from '../../hooks/useFocusManagement';
import { useUser } from '../../context/UserContext';
import { useSendUserInfo } from '../../hooks/useSendUserInfo';
import '../../styles/inputStyles.css';
import { makeValidationSchema } from './validationSchema';
import useBackHandler from '../../hooks/useBackHandler';

const ChangePasswordForm = ({ onSubmit, onBack }) => {
    const { user, setUser } = useUser();
    const { sendUserInfo, loading } = useSendUserInfo();
    const [authError, setAuthError] = useState(null);
    const [initialValues, setInitialValues] = useState({ oldPassword: '', newPassword: '', cofirmNewPassword: '' });

    // Загрузка метаданных полей из JSON
    const fieldMetadataArray = appConfig[user.language]["restorePasswordMetadataArray"];
    const commonText = appConfig[user.language]["common"];

    const validationSchema = makeValidationSchema(user.language);

    const onSubmitForm = async (values) => {
        console.log('RestorePasswordForm onSubmitForm values:', values);
        const response = await sendUserInfo({
                oldPassword: values.oldPassword,
                newPassword: values.newPassword
            });
        console.log('RestorePasswordForm onSubmitForm response:', response);

        if (!response.success) {
            setAuthError(response.error);
        } else {
            onSubmit();
        }
    };

    useBackHandler(onBack);

    // Создание массива идентификаторов ссылок
    const refIdentifiers = fieldMetadataArray.map(item => item.name);

    // Хуки для управления фокусом
    const { setRef, removeFocusFromAll, nextFieldFocus } = useFocusManagement(refIdentifiers);

    if (loading) {
        return <div className="centered-container">
            <div className="loading-spinner"></div>
        </div>;
    }

    const fieldsConfig = fieldMetadataArray.map(metadata => ({
        ...metadata,
        component: componentInstaller(metadata.component),
    }));



    return (
        <div className="overlay bottom-div parent-centered-div">
            <div className="centered-div">
                <h2 className="titleText">{commonText["Change password"]}</h2>
                <CustomForm
                    fieldsConfig={fieldsConfig}
                    initialValues={{ initialValues }}
                    setRef={setRef}
                    removeFocusFromAll={removeFocusFromAll}
                    nextFieldFocus={nextFieldFocus}
                    validationSchema={validationSchema}
                    onSubmit={onSubmitForm}
                    submitText={commonText["Select"]}
                />
                {authError && <div className="error-text">{authError}</div>}
            </div>
        </div>
    );
};

export default ChangePasswordForm;
