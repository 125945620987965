// services/authService.js
import axios from 'axios';

import { BASE_URL, API_VERIFY_USER_TOKEN_ENDPOINT } from '../config/config';

// Отправляет запрос на сервер для верификации токена пользователя
const sendTokenVerificationRequest = async (userToken) => {
    const url = `${BASE_URL}${API_VERIFY_USER_TOKEN_ENDPOINT}`;

    return axios.post(url, {}, {
        headers: {
            'Authorization': `Bearer ${userToken}`
        },
        validateStatus: status => status < 500
    });
};

// Обрабатывает успешный ответ сервера, проверяя наличие и валидность данных
const processTokenVerificationResponse = (response) => {
    if (!response.data || !response.data.data || typeof response.data.data !== 'object') {
        return handleErrorResponse('Invalid data format received from server', 500);
    }

    return {
        status: 200,
        data: response.data.data
    };
};

// Генерирует и возвращает объект ошибки, логируя сообщение об ошибке
const handleErrorResponse = (errorMessage, errorStatus) => {
    console.warn('Authentication error:', errorMessage);
    return { status: errorStatus, data: { error: errorMessage } };
};

// Основная функция сервиса, управляющая запросом и обработкой ответов от сервера
const authenticateTokenOnServer = async () => {
    const userToken = localStorage.getItem('userToken');

    try {
        const response = await sendTokenVerificationRequest(userToken);

        if (response.status === 200) {
            return processTokenVerificationResponse(response);
        } else {
            return handleErrorResponse(response.data.data.error, response.status);
        }
    } catch (error) {
        return handleErrorResponse(error.message || 'Unknown error occurred', error.response ? error.response.status : 500);
    }
};

//const authenticateTokenOnServer = async () => {

//    //const endpoint = '/api/verifyUserToken/';
//    const url = `${BASE_URL}${API_VERIFY_USER_TOKEN_ENDPOINT}`;

//    const userToken = localStorage.getItem('userToken');

//    const response = await axios.post(url, {}, {
//        headers: {
//            'Authorization': `Bearer ${userToken}`
//        },
//        validateStatus: status => status < 500
//    });

//    return  {
//        status: 200,
//        data: response.data.data
//    };
//};

export default authenticateTokenOnServer;