import React, { useState, useImperativeHandle, forwardRef } from 'react';

import '../../styles/inputStyles.css';
import './GenderPicker.css';
import appConfig from '../../static/json/appConfig.json';
import { useUser } from '../../context/UserContext';

const GenderPicker = forwardRef(({ onSelect, removeFocusFromAll, name, label, form, field, nextFieldFocus, setRef }, ref) => {
    console.log("GenderPicker is rendered");

    const [selectedGender, setSelectedGender] = useState(field.value || '');
    const [isFocused, setFocused] = useState(false);
    const { user } = useUser();

    const commonText = appConfig[user.language]["common"];

    useImperativeHandle(setRef(name), () => ({
        removeFocus: () => {
            setFocused(false);
        },
        isFocused: () => {
            return isFocused;
        },
        SetFocus: () => {
            setFocused(true);
        },
        myUniqueId: name
    }), [isFocused, name]);

    const handleFocus = () => {
        if (!isFocused) {
            removeFocusFromAll(name);
            setFocused(true);
        }
    };

    const handleBlur = () => {
        setTimeout(() => {
            nextFieldFocus(name);
            console.log("Next field is focused:");
        }, 0);
        console.log("Next field is focused:")
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            removeFocusFromAll(name); // Убрать фокус со всех элементов
            onSelect(e.target.value); // Вызов функции onSelect с текущим значением ввода
            handleBlur();
        }
    };

    const handleGenderSelect = (gender) => {
        setSelectedGender(gender);
        onSelect(gender);
        handleFocus();
    };

    const isSelected = (gender) => selectedGender === gender;
    const buttonSize = selectedGender === 'other' ? 'genderPicker-smaller-button' : 'genderPicker-larger-button';
    const genders = ['male', 'female', 'other'];
    const capitalize = (s) => s.charAt(0).toUpperCase() + s.slice(1);

    return (
        <div className="container">
            <label className="text">{label}</label>
            <div className={`inputStyles-border genderPicker-container`}>
                {genders.map(gender => (
                    <button
                        key={gender}
                        className={`genderPicker-radioButton ${selectedGender === gender ? 'genderPicker-selectedButton' : ''} ${buttonSize}`}
                        onClick={() => handleGenderSelect(gender)}
                    >
                        <span className={`text ${isSelected(gender) ? 'genderPicker-radioTextSelected' : ''}`}>
                            {commonText[capitalize(gender)]}
                        </span>
                    </button>
                ))}
               
                {selectedGender === 'other' && (
                    <input
                        type="text"
                        className={`text border genderPicker-inputText genderPicker-wide-input  ${isFocused ? 'focused' : ''}`}
                        placeholder="Enter your gender"
                        onChange={(e) => onSelect(e.target.value)}
                        onFocus={handleFocus}
                        onKeyDown={handleKeyDown}
                    />
                )}
            </div>
            {form.touched[field.name] && form.errors[field.name] && (
                <div className="errorText">{form.errors[field.name]}</div>
            )}
        </div>
    );
});

export default GenderPicker;