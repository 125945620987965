import React from 'react';
import './IconButton.css';


const IconButton = ({ name, icon, onSubmit }) => {
    return (
        <div className="icon-button-padding">
            <button
                className="icon-button-container"
                onClick={() => onSubmit(name)}>
                <div class="icon-button-wrapper">
                    <img
                        src={icon}
                        className="icon-button-imageStyle"
                    />
                </div>
                <span className="text icon-button-text">{name}</span>
            </button>
        </div>
    );
};

export default IconButton;