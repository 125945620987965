import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../../styles/inputStyles.css';
import colors from '../../styles/colors';
import './TimeInput.css';

const TimeInput = forwardRef(({ label, field, form, removeFocusFromAll, name, placeholder, nextFieldFocus, setRef }, ref) => {
    console.log("TimeInput is rendered")
    const [selectedTime, setSelectedTime] = useState(new Date(`1970-01-01T${field.value || '00:00:00'}`));
    const [isFocused, setFocused] = useState(false);

    useImperativeHandle(setRef(name), () => ({
        removeFocus: () => {
            setFocused(false);
        },
        isFocused: () => {
            return isFocused;
        },
        SetFocus: () => {
            setFocused(true);
        },
        myUniqueId: name
    }), [isFocused, name]);

    const handleChange = (selectedTime) => {
        if (selectedTime) {
            setSelectedTime(selectedTime);
            const formattedTime = selectedTime.toTimeString().split(' ')[0];
            form.setFieldValue(field.name, formattedTime);
            form.setFieldTouched(field.name, true);
        }
    };

    const handleBlur = () => {
        setTimeout(() => {
            nextFieldFocus(name);
            console.log("Next field is focused:");
        }, 0);
        console.log("Next field is focused:")
    };

/*    console.log("TimeInput is focused:", isFocused)*/

    return (
        <div className="container">
            <label className="text">{label}</label>
            <div
                onBlur={() => {
                    form.handleBlur(field.name);
                }}
                className={`border  ${isFocused ? 'focused' : ''} parent-centered-div`}
            >
                <DatePicker
                    selected={selectedTime}
                    onChange={(time) => {
                        handleChange(time);
                        handleBlur();  // Перенос вызова handleBlur сюда
                    }}
                    onInputClick={() => {
                        removeFocusFromAll(name);
                        setFocused(true);
                    }}
                    open={isFocused}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                    className="timeinput-time-picker"
                    calendarClassName="timeinput-time-calendar"
                    popperClassName="timeinput-time-popper"
                    placeholderText={placeholder}
                    customInput={
                        <div className={`text timeinput-text ${!selectedTime ? 'placeholder-text-color' : ''}`}>
                            {selectedTime instanceof Date ? selectedTime.toTimeString().split(' ')[0] : placeholder}
                        </div>
                    }
                />
            </div>
            {form.touched[field.name] && form.errors[field.name] ? (
                <div className="error-text">{form.errors[field.name]}</div>
            ) : null}
        </div>
    );
});

export default TimeInput;