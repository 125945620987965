import React from "react";
import { Formik } from 'formik';
import ScrollingText from '../common/ScrollingText';
import CheckboxLabel from '../common/CheckboxLabel';
import SubmitButton from '../common/SubmitButton';
import termsOfUseJson from '../../static/json/termOfUse.json';
import { useUser } from '../../context/UserContext';
import appConfig from '../../static/json/appConfig.json';
import './Terms.css';
import '../../styles/inputStyles.css';
import useBackHandler from '../../hooks/useBackHandler';

const Terms = ({ goToSignUp, onBack, showForm }) => {
    const { user, setUser } = useUser();
    const commonText = appConfig[user.language]["common"];

    const onSubmitFormik = (values) => {
        setUser(prevUser => ({
            ...prevUser,
            agreeToTerms: values.agreeToTerms
        }));

        goToSignUp();
    };

    const termsArray = termsOfUseJson[user.language].TermOfUse;

    useBackHandler(onBack);

    return (
        <div className="overlay terms-container">
            <ScrollingText text={termsArray}/>
            {showForm ? (
                <Formik
                    initialValues={{ agreeToTerms: user.agreeToTerms || false }}
                    onSubmit={onSubmitFormik}
                >
                    {({ handleSubmit }) => (
                        <div>
                            <CheckboxLabel name="agreeToTerms" label={commonText["I agree"]} />
                            <SubmitButton
                                text={commonText["Continue"]}
                                onSubmit={handleSubmit} />
                        </div>
                    )}
                </Formik>
            ) : (
                <SubmitButton
                    text="Return"
                    onSubmit={() => {
                        onBack();
                    }}
                />
            )}
        </div>
    );
};

export default Terms;