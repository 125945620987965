import React from 'react';
import './ScrollingText.css';
import '../../styles/inputStyles.css';

const ScrollingText = ({ text, maxHeight }) => {
    return (
        <div className="scrollingtext-background" style={{ maxHeight }}>
            <div>
                {/* First line in bold */}
                <p className="text width100 scrollingtext-text scrollingtext-boldText">
                    {text[0]}
                    <br />
                </p>
                {/* Remaining text */}
                {text.slice(1).map((line, index) => (
                    <p key={index} className="text scrollingtext-text ">
                        {line}
                        <br />
                    </p>
                ))}
            </div>
        </div>
    );
};

export default ScrollingText;