// FeatureBox.js
import React from 'react';

import '../../styles/inputStyles.css';
import '../../styles/colors.css';
import '../../styles/designConstants.css';

import './FeatureBox.css';

const FeatureBox = ({ label, isChecked }) => {
    return (
        <div className="featurebox-container">
            <div className={`border featurebox-box ${isChecked ? 'featurebox-checked' : ''}`}>
                {isChecked && <span className="featurebox-tick">✔</span>}
            </div>
            <span className="text featurebox-label">{label}</span>
        </div>
    );
};

export default FeatureBox;