import axios from 'axios';
import { IS_TEST_MODE, BASE_URL, API_PROMO_ENDPOINT } from '../config/config';

// Отправляет запрос для получения информации по промокоду
const sendPromoCodeRequest = async (promoCode) => {
    const url = `${BASE_URL}${API_PROMO_ENDPOINT}`;
    const token = localStorage.getItem('userToken');

    return axios.post(url, {
        promo_code: promoCode,
    }, {
        headers: {
            "Authorization": `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        validateStatus: status => status < 500
    });
};

// Обрабатывает успешный ответ сервера
const processSuccessfulResponse = (response) => {
    if (!response.data || !response.data.data) {
        return handleErrorResponse('Invalid data format from server', 500);
    }

    return {
        status: 200,
        data: response.data.data
    };
};

// Генерирует объект ошибки, логирует ее и возвращает структурированный ответ об ошибке
const handleErrorResponse = (errorMessage, errorStatus) => {
    console.error('Fetch promo code error:', errorMessage);
    return { status: errorStatus, data: { error: errorMessage } };
};

// Основная функция для получения информации по промокоду
const fetchPromoCode = async (promoCode) => {
    if (!promoCode) {
        return handleErrorResponse('Promo code is required', 400);
    }

    if (IS_TEST_MODE) {
        // Возвращает мок-данные для тестирования
        const mockData = {
            code: promoCode,
            discount_amount: 10,
            discount_type: 'Relative',
            usage_type: 'One-time',
            is_active: true,
            created_at: new Date().toISOString(),
            expires_at: new Date(new Date().getTime() + 86400000).toISOString(),
            user: null,
            partner_commission: 0.1,
        };
        return {
            status: 200,
            data: mockData
        };
    }

    try {
        const response = await sendPromoCodeRequest(promoCode);

        if (response.status === 200) {
            return processSuccessfulResponse(response);
        } else {
            return handleErrorResponse(response.data.error, response.status);
        }
    } catch (error) {
        return handleErrorResponse(error.message || 'Unknown error occurred', error.response ? error.response.status : 500);
    }
};

export default fetchPromoCode;
