import React from 'react';
import { useFormikContext } from 'formik';

import { makeValidationSchema } from './validationSchema';
import useFocusManagement from '../../hooks/useFocusManagement';
import { useScreenOffsetControl } from '../../hooks/useScreenOffsetControl';
import CustomForm from '../common/CustomForm';
import { componentInstaller } from '../../utils/componentInstaller';

import { useUser } from '../../context/UserContext';
import appConfig from '../../static/json/appConfig.json';

const SignInForm = ({ onSubmit, initialValues, formRef }) => {
    console.log('SignInForm is rendered');
    const formik = useFormikContext();

    const { user } = useUser();
    const commonText = appConfig[user.language]["common"];

    const submitText = user.registrated ? commonText["Select"] : commonText["Continue"];
    const validationSchema = makeValidationSchema(user.language);

    const fieldMetadataArray = appConfig[user.language]["signInMetadataArray"];

    const refIdentifiers = fieldMetadataArray.map(item => item.name);

    const screenOffsets = Object.fromEntries(
        fieldMetadataArray.map(item => [item.name, item.screenOffset])
    );

    const [screenOffset, setFieldOffset, , updateContentHeight] = useScreenOffsetControl();

    const { setRef, removeFocusFromAll, nextFieldFocus } = useFocusManagement(refIdentifiers);

    const fieldsConfig = fieldMetadataArray.map(metadata => ({
        ...metadata,
        component: componentInstaller(metadata.component),
    }));

    return (
        <CustomForm
            fieldsConfig={fieldsConfig}
            setRef={setRef}
            removeFocusFromAll={removeFocusFromAll}
            nextFieldFocus ={nextFieldFocus}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            submitText={submitText}
            formRef={formRef}
        />
    );
};

export default SignInForm;