import axios from 'axios';
import { IS_TEST_MODE, BASE_URL, API_ARTICLE_ENDPOINT } from '../config/config';
import catImage from '../static/image/cat.webp';

const getMockArticlesData = (recipient, articleType, missingDates) => {
    const text = `😊😊😊😊
💸💸💸
💖💖💖
💚💚💚

**Абстракт:**
Этот период посвящен балансу между личными амбициями и поддержанием прочных отношений.

**Основной раздел:**
Жизнь – крепкая печенька, и прямо сейчас она преподносит вам смесь сахара и гравия. Будьте готовы к испытанию нервов и решимости. Вы обнаружите, что совмещаете свои амбиции и требования тех выдающихся личностей, которые вас окружают. Боссы, авторитетные фигуры или, возможно, ожидания накапливались, как старые газеты, готовые рухнуть. Это тонкая грань для навигации. С одной стороны, ваши амбиции горят, вы стремитесь воспользоваться возможностями, которые проглядывают сквозь хаос. Но будьте осторожны – ловушка гордости и желание все контролировать, как какой-нибудь кукловод, могут поставить вас в затруднительное положение.`;

    const lines = text.split('\n');
    const htmlContent = lines.join('<br>');

    return new Promise(resolve => {
        setTimeout(() => {
            const data = missingDates.map((date) => ({
                id: Math.random().toString(36).substr(2, 9),
                recipient,
                astrobot: 'Bruce',
                articleType,
                publication_date: date,
                title: `Тест для ${date}`,
                content: htmlContent,
                image: catImage
            }));

            resolve({
                status: 200,
                data: data
            });
        }, 500);
    });
};

// Отправляет запрос для получения статей
const sendArticlesRequest = async (recipient, articleType, missingDates) => {
    const url = `${BASE_URL}${API_ARTICLE_ENDPOINT}`;
    const token = localStorage.getItem('userToken');

    return axios.post(url, {
        recipient,
        articleType,
        dates: missingDates,
    }, {
        headers: {
            "Authorization": `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        validateStatus: status => status < 500
    });
};

// Обрабатывает успешный ответ сервера
const processSuccessfulResponse = (response) => {
    if (!response.data || !response.data.data || !Array.isArray(response.data.data)) {
        return handleErrorResponse('Invalid data format from server', 500);
    }

    return {
        status: 200,
        data: response.data.data
    };
};

// Генерирует объект ошибки, логирует ее и возвращает структурированный ответ об ошибке
const handleErrorResponse = (errorMessage, errorStatus) => {
    console.error('Fetch articles error:', errorMessage);
    return { status: errorStatus, data: { error: errorMessage } };
};

// Основная функция для получения статей, обрабатывает всю логику запроса и ответа
const fetchArticles = async (recipient, articleType, missingDates) => {
    if (IS_TEST_MODE) {
        return getMockArticlesData(recipient, articleType, missingDates);
    }

    try {
        const response = await sendArticlesRequest(recipient, articleType, missingDates);

        if (response.status === 200) {
            return processSuccessfulResponse(response);
        } else {
            return handleErrorResponse(response.data.error, response.status);
        }
    } catch (error) {
        return handleErrorResponse(error.message || 'Unknown error occurred', error.response ? error.response.status : 500);
    }
};

export default fetchArticles;






