import React, { useRef } from 'react';
import { Formik, Field } from 'formik';

import SubmitButton from './SubmitButton'; // Убедитесь, что SubmitButton поддерживает веб
import './CustomForm.css'; // Убедитесь, что стили определены в CSS

const CustomForm = ({
        fieldsConfig,
        setRef,
        removeFocusFromAll,
        nextFieldFocus,
        initialValues,
        validationSchema,
        onSubmit,
        submitText = "CONTINUE",
        formRef 
    }) => {

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            innerRef={formRef ? formRef : undefined}
            onSubmit={(values, actions) => {
                console.log('Form submitted with values:', values);
                onSubmit(values); // Здесь values - это значения формы
                // Дополнительные действия после отправки можно выполнить с помощью actions
            }}
            enableReinitialize={true}
        >
            {({ handleSubmit, values, errors, touched, isValidating, isSubmitting }) => {
                console.log('Formik render with values:', values);
                console.log('Formik render with errors:', errors);
                console.log('Formik render with touched:', touched);
                return (
                    <div className="custom-form-container">
                        {fieldsConfig.map((fieldConfig, index) => {
                            //console.log('Rendering component with render:', fieldConfig.component.render);
                            //console.log('Type of component:', typeof fieldConfig.component.render);

                            if (!fieldConfig.component) {
                                console.error(`Компонент для ${fieldConfig.name} не определен или равен null`);
                            }

                            return (
                                <Field key={index} name={fieldConfig.name}>
                                    {({ field, form }) => {


                                        return React.createElement(
                                            fieldConfig.component.render,
                                            {
                                                name: fieldConfig.name,
                                                label: fieldConfig.label,
                                                field: field,
                                                type: fieldConfig.type,
                                                initialValue: initialValues[fieldConfig.name],
                                                form: form,
                                                setRef: setRef,
                                                removeFocusFromAll: removeFocusFromAll,
                                                nextFieldFocus: nextFieldFocus,
                                                placeholder: fieldConfig.placeholder,
                                                ...fieldConfig.additionalProps,
                                                onSelect: (value) => {
                                                    if (fieldConfig.additionalProps && fieldConfig.additionalProps.onSelect) {
                                                        fieldConfig.additionalProps.onSelect(value, form);
                                                    } else {
                                                        form.setFieldValue(fieldConfig.name, value);
                                                    }
                                                }
                                            }
                                        )
                                    }}
                                </Field>
                            );
                        })}
                        <SubmitButton
                            text={submitText}
                            onSubmit={() => {
                                console.log('SubmitButton clicked');
                                handleSubmit();
                                //console.log('CustomForm SubmitButton handleSubmit выполнен');
                            }}
                        />
                    </div>
                );
            }}
        </Formik>
    );
};

export default CustomForm;
