const logout = async () => {
    try {
        console.log('Выход из системы');
        localStorage.removeItem('userToken'); // Использование localStorage для веба
        // Вы можете добавить здесь дополнительные действия, например, перенаправление пользователя на экран входа
        // Например, если используется React Router:
        // history.push('/login');
    } catch (error) {
        console.error('Ошибка при попытке выйти из системы:', error);
    }
};

export default logout;