import React, { useState } from 'react';
import Confirmation from './Confirmation';
import UnsupportedCountryNotice from './UnsupportedCountryNotice';
import useIsVATRequired from '../../hooks/useIsVATRequired';

const PaymentProcess = ({ amount, plan, period, onBack }) => {
    const { isVATRequired, error } = useIsVATRequired();

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    return (
        isVATRequired ? (
            <UnsupportedCountryNotice onBack={onBack} />
        ) : (
                <Confirmation amount={amount} plan={plan} period={period} onBack={onBack} />
        )
    );
};

export default PaymentProcess;