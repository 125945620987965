// SignIn.js
import React, { useState, useRef } from "react";
import Container from '../common/Container';
import SocialLogin from '../socialLogin/SocialLogin';
import PromptWithActionLink from '../common/PromptWithActionLink';
import SignInForm from './SignInForm';
import './SignIn.css';
import '../../styles/inputStyles.css';
import { useSignInAuthentication } from '../../hooks/useSignInAuthentication';
import { usePasswordReset } from '../../hooks/usePasswordReset';
import { useUser } from '../../context/UserContext';
import appConfig from '../../static/json/appConfig.json';
import useBackHandler from '../../hooks/useBackHandler';

const SignIn = ({ goToPrediction, goToSignUp, onBack }) => {
    console.log('SignIn is rendered');

    const { authenticateSignInUser, loading: signInAuthenticationLoading  } = useSignInAuthentication();
    const [authError, setAuthError] = useState(null);
    const [initialValues, setInitialValues] = useState({ username: '', password: '' });
    const { user, setUser } = useUser();
    const [restorePasswordMessage, setRestorePasswordMessage] = useState(null);
    const { resetPassword, loading: resetPasswordLoading } = usePasswordReset();
    const formRef = useRef(null);


    const commonText = appConfig[user.language]["common"];

    const onSubmit = async (values) => {
        const response = await authenticateSignInUser(values);
        console.log('SignIn onSubmit response:', response);
        if (response.success) {
            if (response.user.is_registration_completed) {
                goToPrediction();
            } else {

            }
        }
        else {
            setAuthError(response.error);
            setInitialValues(values);
        }
    };

    useBackHandler(onBack);

    const getFormValues = () => {
        if (formRef.current && formRef.current.values) {
            return formRef.current.values;
        }
        return null;
    };

    const goToRestorePassword = async () => {
        const formValues = getFormValues();
        if (formValues) {
            const response = await resetPassword(formValues.username);
            if (response.success) {
                setRestorePasswordMessage('Password reset email sent successfully!');
            } else {
                setAuthError(response.error);
            }
            setInitialValues(formValues);
        }
    };

    if (signInAuthenticationLoading || resetPasswordLoading) {
        return (
            <div className="loading-container">
                <div className="loading-spinner"></div>
            </div>
        );
    }

    return (
        <div className="overlay bottom-div parent-centered-div">
            <div className="centered-div signin-centered-div">

                <h2 className="titleText signin-titleText">{commonText["Sign in to your account"]}</h2>

                {authError && <p className="errorText">{authError}</p>}
                <div className="signin-item-container" >
                    <SignInForm
                        formRef={formRef}
                        onSubmit={onSubmit}
                        initialValues={initialValues} />
                </div>
                <div className="signin-item-container" >
                    <SocialLogin />
                </div>
                <div className="signin-item-container" >
                    <PromptWithActionLink
                        prompt={commonText["Forgotten your password?"]}
                        buttonText={commonText["Send letter to restore"]}
                        onLinkPress={goToRestorePassword}
                    />
                    {restorePasswordMessage && <p className="text signin-message">{restorePasswordMessage}</p>}
                </div>
                <div className="signin-item-container" >
                    <PromptWithActionLink
                        prompt={commonText["Don't have an account?"]}
                        buttonText={commonText["Sign Up"]}
                        onLinkPress={goToSignUp}
                    />
                </div>

            </div>

        </div>
    );
};

export default SignIn;