import React from 'react';
import './AstrologAIText.css'; // Убедитесь, что путь к файлу правильный


const AstrologAIText = () => {
    return (
        <div class="astrologyai-container">
            <div class="astrologyai-text"> 
                Astrology AI
            </div>
        </div>
    );
};

export default AstrologAIText;