import React, { useState, useRef } from 'react';
import fetchArticles from '../services/fetchArticles';
import { addArticles, getAllArticles, deleteArticles } from './articlesModel';

import { BASE_URL } from '../config/config'; 

const NUMBER_OF_ARTICLES = 6;
const LIMIT_OF_DAILY_ARTICLES = 0;
const LIMIT_OF_WEEKLY_OR_MONTHLY_ARTICLES = 0;

const useArticles = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [currentRecipient, setCurrentRecipient] = useState('');
    const [currentArticleType, setCurrentArticleType] = useState('');
    const articlesRef = useRef([]);

    const nextArticles = async (user, recipient, articleType, startIndex = null) => {
        let localCurrentIndex = startIndex !== null ? startIndex : currentIndex;

        if (currentRecipient !== recipient ||
            currentArticleType !== articleType ||
            articlesRef.current.length === 0)
        {
            setCurrentRecipient(recipient);
            setCurrentArticleType(articleType);

            await initializeArticles(recipient, user, articleType);

            localCurrentIndex = 0; // Нужна для сбрасывания индекса до 0 при смене получателя или типа статьи
        }

        const newArticles = await checkAndFetchMissingArticles(user, recipient, articleType, localCurrentIndex, NUMBER_OF_ARTICLES);
        localCurrentIndex += NUMBER_OF_ARTICLES;
        setCurrentIndex(localCurrentIndex);

        const articlesToReturn = newArticles.slice(0, localCurrentIndex);

        return articlesToReturn;
    };

    const initializeArticles = async (recipient, user, articleType) => {
        const language = user.language
        const localArticles = await getAllArticles({ recipient, articleType, language });
        await checkAndRemoveOldArticles(localArticles, articleType);
        articlesRef.current = localArticles;
    };

    const checkAndRemoveOldArticles = async (localArticles, articleType) => {
        try {
            const limit = articleType === 'Day' ? LIMIT_OF_DAILY_ARTICLES : LIMIT_OF_WEEKLY_OR_MONTHLY_ARTICLES;
            if (localArticles.length > limit) {
                const idsToDelete = localArticles.slice(limit).map(article => article.id);
                await deleteArticles(idsToDelete);
                articlesRef.current = localArticles.slice(0, limit);
            }
            return localArticles;
        } catch (error) {
            console.error('Error in checkAndRemoveOldArticles:', error);
            throw error;
        }
    };

    const filterDates = (datesToCheck, userRegistrationDate, appDate, userName, recipient) => {
        return (userName === recipient) ?
            datesToCheck.filter(date => new Date(date) >= userRegistrationDate) :
            datesToCheck.filter(date => new Date(date) >= appDate);
    };

    const fetchMissingDates = async (recipient, articleType, missingDates) => {
        const response = await fetchArticles(recipient, articleType, missingDates);
        if (response.status === 200) {
            const newArticles = response.data.map(article => ({
                ...article,
                content: article.content.replace(/\n/g, '<br>'),
                image: `${BASE_URL}/${article.image}`,
                article_type: article.article_type === 'D' ? 'Day' :
                    article.article_type === 'W' ? 'Week' :
                        article.article_type === 'M' ? 'Month' :
                            article.article_type
            }));

            await addArticles(newArticles);
            articlesRef.current = [
                ...articlesRef.current,
                ...newArticles.filter(article => !articlesRef.current.some(a => a.id === article.id))
            ];
        } else {
            console.log('fetchMissingDates Error:', response.data.error);
        }
    };

    const checkAndFetchMissingArticles = async (user, recipient, articleType, startIndex, number) => {
        try {
            const userName = user.name;
            const userRegistrationDate = new Date(user.registration_data);
            const appDate = new Date('2023-01-27');
            const datesToCheck = getDatesToCheck(articleType, startIndex, number);
            const filteredDates = filterDates(datesToCheck, userRegistrationDate, appDate, userName, recipient);

            const missingDates = filteredDates.filter(date => {
                return !articlesRef.current.some(article => article.publication_date === date);
            });

            if (missingDates.length > 0) {
                await fetchMissingDates(recipient, articleType, missingDates);
            }

            return articlesRef.current.sort((a, b) => new Date(b.publication_date) - new Date(a.publication_date));
        } catch (error) {
            console.error('Error in checkAndFetchMissingArticles:', error);
            throw error;
        }
    };

    const getDatesToCheck = (articleType, startIndex, number) => {
        let dates = [];
        let startDate = new Date();

        if (articleType === 'Week') {
            startDate.setDate(startDate.getDate() - startDate.getDay() + 1);
        } else if (articleType === 'Month') {
            startDate.setDate(1);
        }

        for (let i = startIndex; i < startIndex + number; i++) {
            const checkDate = new Date(startDate);

            if (articleType === 'Week') {
                checkDate.setDate(startDate.getDate() - i * 7);
            } else if (articleType === 'Month') {
                checkDate.setMonth(startDate.getMonth() - i);
            } else {
                checkDate.setDate(startDate.getDate() - i);
            }

            dates.push(checkDate.toISOString().split('T')[0]);
        }
        return dates;
    };

    return { nextArticles };
};

export { useArticles };