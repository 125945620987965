import React, { useState, useEffect } from 'react';
import useCountryByIP from './useCountryByIP';


const countriesWithZeroThreshold = ['RU', 'AR', 'BR', 'CL', 'CO'];

const useIsVATRequired = () => {
    const { country, error } = useCountryByIP();
    const [isVATRequired, setIsVATRequired] = useState(false);

    useEffect(() => {
        if (country && !error) {
            if (countriesWithZeroThreshold.includes(country)) {
                setIsVATRequired(true);
            } else {
                setIsVATRequired(false);
            }
        }
    }, [country, error]);

    return { isVATRequired, error };
};

export default useIsVATRequired;
