import { useEffect, useState } from 'react';
import appConfig from '../static/json/appConfig.json'; // Путь к вашему appConfig
import { useUser } from '../context/UserContext';

const imageContext = require.context('../static/image', true);

const useZodiacImages = () => {
    const [zodiacImages, setZodiacImages] = useState([]);
    const { user, setUser } = useUser();

    useEffect(() => {
        const zodiacs = appConfig[user.language].Zodiacs;
        const loadedImages = zodiacs.map(zodiac => ({
            ...zodiac,
            imageSrc: imageContext('./' + zodiac.image)
        }));

        setZodiacImages(loadedImages);
    }, []);

    return zodiacImages;
};

export default useZodiacImages;