import { IS_TEST_MODE } from '../config/config';

export async function appleService() {
    try {
        if (IS_TEST_MODE) {
            return new Promise(resolve => setTimeout(() => {
                resolve({
                    status: 200,
                    data: {
                        token: 'mock_apple_access_token'
                    }
                });
            }, 500));
        } else {
            // В React.js необходимо реализовать аутентификацию Apple через серверную часть
            // Пример ниже предполагает, что на сервере уже настроена аутентификация Apple
            const response = await fetch('/api/auth/apple', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ /* данные запроса, если необходимы */ })
            });

            const data = await response.json();

            if (response.ok) {
                return {
                    status: 200,
                    data: {
                        token: data.token
                    }
                };
            } else {
                return { status: response.status, data: { error: data.error || 'Apple Sign-In failed' } };
            }
        }
    } catch (error) {
        return {
            status: 500,
            data: { error: error.message }
        };
    }
}