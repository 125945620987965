import { openDB } from 'idb';

const initializeDatabase = async () => {
    const db = await openDB('articlesDB', 1, {
        upgrade(db) {
            const store = db.createObjectStore('articles', { keyPath: 'id', autoIncrement: true });
            store.createIndex('recipient', 'recipient', { unique: false });
            store.createIndex('article_type', 'article_type', { unique: false });
            store.createIndex('publication_date', 'publication_date', { unique: false });
            // Добавил остальные поля в объекте store для ясности, но индексы не нужны
            store.createIndex('title', 'title', { unique: false });
            store.createIndex('content', 'content', { unique: false });
            store.createIndex('image', 'image', { unique: false });
        },
    });

    console.log('Database initialized');
    return db;
};

export default initializeDatabase;
