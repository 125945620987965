import React, { useRef, useEffect, useState, useCallback } from 'react';

import '../../styles/inputStyles.css';

import BackgroundImage from '../BackgroundImage/BackgroundImage';
import AstrologAIText from '../AstrologAIText/AstrologAIText';
import { useTokenAuthentication } from '../../hooks/useTokenAuthentication';

import SignIn from '../signin/SignIn';
import SignUp from '../signup/SignUp';
import Prediction from '../prediction/Prediction';
import Verification from '../verification/Verification';
import Subscription from '../subscription/Subscription';
import Confirmation from '../subscription/Confirmation';
import UnsupportedCountryNotice from '../subscription/UnsupportedCountryNotice';
import OrderCompletedSuccess from '../subscription/OrderCompletedSuccess';
import Profile from '../profile/Profile';
import Terms from '../terms/Terms';
import GreetingForm from '../GreetingForm/GreetingForm';
import LanguageSelector from '../language/LanguageSelector';
import LanguageForm from '../language/LanguageForm';
import { useUser } from '../../context/UserContext';
import TranzilaPaymentForm from '../subscription/TranzilaPaymentForm';
import Footer from '../footer/Footer';
import ContactPage from '../footer/ContactPage';

import './MainPage.css';

function MainPage() {
    const [currentScreen, setCurrentScreen] = useState('null');
    const [screenHistory, setScreenHistory] = useState([]);
    const { checkToken } = useTokenAuthentication();
    const { user, setUser } = useUser();

    useEffect(() => {
        const authenticate = async () => {
            const urlParams = new URLSearchParams(window.location.search);
            const authData = urlParams.get('auth_data');

            if (authData) {
                // Если есть данные аутентификации в URL, обрабатываем их
                const decodedData = JSON.parse(decodeURIComponent(authData));
                if (decodedData.token)
                    localStorage.setItem('userToken', decodedData.token);

                // Очищаем URL от параметров аутентификации
                window.history.replaceState({}, document.title, window.location.pathname);
            }

            const response = await checkToken();
            if (response.success) {
                if (!response.user.is_registration_completed) {
                    goToScreen('Profile');
                }
                if (response.user.birth_country === "") {
                    goToScreen('Profile');
                } else if (response.user.subscriptionType == "NONE") {
                    goToScreen('Subscription');
                }
                else {
                    goToScreen('Prediction');
                }
            } else {
                goToScreen('GreetingForm');
            }
            
        };

        authenticate();
    }, []);

    const goToScreen = (screen) => {
        setCurrentScreen(screen);
        setScreenHistory([...screenHistory, screen]);
    };

    const goBack = () => {
        if (screenHistory.length > 1) {
            const newScreenHistory = [...screenHistory];
            newScreenHistory.pop();
            setScreenHistory(newScreenHistory);
            setCurrentScreen(newScreenHistory[newScreenHistory.length - 1]);
        }
    };

    const goToSignUp = () => goToScreen('SignUp');
    const goToSignIn = () => goToScreen('SignIn');
  /*  const goToTerms = () => goToScreen('Terms');*/
    const goToVerification = () => goToScreen('Verification');
    const goToProfile = () => goToScreen('Profile');
    const goToSubscription = () => goToScreen('Subscription');
    const goToPrediction = () => goToScreen('Prediction');
    const goToGreetingForm = () => goToScreen('GreetingForm');
    const goToLanguageForm = () => goToScreen('LanguageForm');
    const goToContactPage = () => goToScreen('ContactPage');

    const goToTermsWithForm = () => {
        goToScreen('TermsWithForm');
    };

    const goToTermsWithoutForm = () => {
        goToScreen('TermsWithoutForm');
    };

    const goToConfirmation = () => goToScreen('Confirmation');


    let amount = 10;
    let period = "monthly"
    let plan = "STANDARD"

    return (
        <BackgroundImage>
            <div className="main-page-top-aligned-div">
                <AstrologAIText />
                <LanguageSelector onSubmit={goToLanguageForm} onBack={goBack} />
            </div>
            <div className="bottom-div">

                {/* <TranzilaPaymentForm amount={amount} period={period } />*/}


                {/* {<OrderCompletedSuccess paymentMethod="Google Pay" lastFourDigits = "1234"/> }*/}
                {/* {<UnsupportedCountryNotice/> }*/}
                {/* {<Confirmation amount={amount} plan={plan} period={period} />}*/}
                {/* {<Subscription onSubmit={goToPrediction} onBack={goBack} header="true" />}*/}



                {currentScreen === 'GreetingForm' && <GreetingForm goToSignUp={goToSignUp} goToSignIn={goToSignIn} />}
                {currentScreen === 'SignUp' && <SignUp goToSignIn={goToSignIn}
                    goToTerms={goToTermsWithForm}
                    goToVerification={goToVerification}
                    goToProfile={goToProfile}
                    goToPrediction={goToPrediction}
                    onBack={goBack} />}
                {currentScreen === 'SignIn' && <SignIn goToPrediction={goToPrediction} goToSignUp={goToSignUp} onBack={goBack} />}
                {currentScreen === 'Verification' && <Verification onSubmit={goToProfile} onBack={goBack} />}
                {currentScreen === 'Profile' && <Profile onSubmit={goToSubscription} onBack={goBack} />}

                {currentScreen === 'Subscription' && <Subscription onSubmit={goToPrediction} onBack={goBack} header="true" />}
                {currentScreen === 'Confirmation' && <Confirmation amount={10} plan={"STANDARD"} period={"monthly"} onBack={goBack} />}

                {currentScreen === 'Prediction' && <Prediction goToGreetingForm={goToGreetingForm} />}


                {currentScreen === 'TermsWithForm' && <Terms goToSignUp={goToSignUp} onBack={goBack} showForm={true} />}
                {currentScreen === 'TermsWithoutForm' && <Terms goToSignUp={goToSignUp} onBack={goBack} showForm={false} />}

                {currentScreen === 'LanguageForm' && <LanguageForm onSubmit={goBack} onBack={goBack} />}  
                {currentScreen === 'ContactPage' && <ContactPage onBack={goBack}  />}
            </div>
            <div className="main-page-bottom-aligned-div">
                <Footer goToContactPage={goToContactPage} goToTerms={goToTermsWithoutForm} goToConfirmation={goToConfirmation} />
            </div>
        </BackgroundImage>
    );
}

export default MainPage;
