import { useCallback } from "react";
import { useUser } from '../context/UserContext';

// Хук useUserState используется для обновления состояния пользователя в глобальном контексте.
const useUserState = () => {
    const { setUser } = useUser();

    // Функция updateUserData принимает объект userData и обновляет состояние пользователя,
    // используя предоставленные данные. Она также устанавливает флаг isAuthenticated в зависимости от того,
    // завершена ли регистрация пользователя.
    const updateUserData = useCallback((userData) => {
        setUser(prevUser => ({
            ...prevUser,
            ...userData,
            isAuthenticated: userData.is_registration_completed
        }));
    }, [setUser]);

    return { updateUserData };
};

export default useUserState;