import { useState, useCallback } from 'react';
import sendPasswordResetRequest from '../services/sendPasswordResetRequest';

export const usePasswordReset = () => {
    const [loading, setLoading] = useState(false);

    const resetPassword = useCallback(async (username) => {
        setLoading(true);
        try {
            const response = await sendPasswordResetRequest(username);
            if (response.status === 200 && !response.data.error) {
                return { success: true };
            } else {
                return { success: false, error: response.data.error };
            }
        } finally {
            setLoading(false);
        }
    }, []);

    return {
        resetPassword,
        loading
    };
};