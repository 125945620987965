import { useState, useCallback } from "react";
import performTranzilaHandshake from '../services/performTranzilaHandshake';
import useUserState from './useUserState';

export const useTranzilaHandshake = () => {
    const [loading, setLoading] = useState(false);
    const { updateUserData } = useUserState();

    const getHandshakeToken = useCallback(async (amount) => {
        setLoading(true);
        try {
            const response = await performTranzilaHandshake(amount);
            if (response.status === 200 && !response.data.error) {
                return { success: true, token: response.data.token };
            } else if (response.status === 401) {
                updateUserData({ isAuthenticated: false });
                return { success: false, error: response.data.error };
            } else {
                return { success: false, error: response.data.error };
            }
        } catch (error) {
            return { success: false, error: error.message };
        } finally {
            setLoading(false);
        }
    }, [updateUserData]);

    return { getHandshakeToken, loading };
};

export default useTranzilaHandshake;


//import { useState, useEffect } from 'react';
//import tranzilaCredentials from '../static/json/tranzilaCredentials.json'; // Assuming the credentials are stored in this file

//const useTranzilaHandshake = (amount) => {
//    const [token, setToken] = useState(null);

//    useEffect(() => {
//        const performHandshake = async () => {
//            try {
//                const numericAmount = Number(amount);
//                if (isNaN(numericAmount)) {
//                    console.error('Сумма не является числом:', amount);
//                    return;
//                }
//                const formattedAmount = numericAmount.toFixed(2);
//                const response = await fetch(`https://api.tranzila.com/v1/handshake/create?supplier=${tranzilaCredentials.supplier}&sum=${formattedAmount}&TranzilaPW=${tranzilaCredentials.password}`);
//                console.log('Handshake response:', response);
//                if (!response.ok) {
//                    const errorText = await response.text();
//                    console.error('Ошибка выполнения handshake:', response.status, response.statusText, errorText);
//                    return;
//                }
//                const textResponse = await response.text();
//                console.log('Handshake response:', textResponse);
//                const params = new URLSearchParams(textResponse);
//                const thtk = params.get('thtk');
//                setToken(thtk);
//            } catch (error) {
//                console.error('Ошибка выполнения handshake:', error);
//            }
//        };

//        performHandshake();
//    }, [amount]);

//    return token;
//};

//export default useTranzilaHandshake;

