import React, { createContext, useState, useContext, useEffect } from 'react';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const now = new Date();  // Создаем объект Date, представляющий текущее время
 

    const defaultUserState = {
        name: '',
        email: '',
        password: '',
        gender: "male",
        birth_date: now,
        birth_time: '00:00:00',
        birth_country: "",
        birth_city: "",
        biography: '',
        agreeToTerms: false,
        zodiacSign: null,
        isAuthenticated: false,
        is_registration_completed: false,
        language: 'English',
        generalContent: true,
        businessContent: true,
        relationContent: true,
        healthContent: false,
        subscriptionType: 'NONE',
        subscriptionPerMonth: 0,
        subscriptionPerYear: 0,
        verificationCode: "",
        registration_data: now,
        useEmail: true,
        useWhatsApp: false,
        useTelegram: false,
        usePushMessage: false,
        phone: "",
    };

    const [user, setUserState] = useState(defaultUserState);

    useEffect(() => {
        //console.log('User state changed:', user);
    }, [user]);

    const setUser = (newState) => {
        setUserState(newState);
    };

    const resetUser = () => {
        setUserState(defaultUserState);
    };

    return (
        <UserContext.Provider value={{ user, setUser, resetUser }}>
            {children}
        </UserContext.Provider>
    );
};

export const useUser = () => {
    const context = useContext(UserContext);
    if (!context) {
        throw new Error('useUser must be used within a UserProvider');
    }
    return context;
};