import axios from 'axios';
import { IS_TEST_MODE, BASE_URL, API_TRANZILA_HANDSHAKE_ENDPOINT } from '../config/config';

const getMockTranzilaHandshakeData = (amount) => {
    return {
        status: 200,
        data: {
            token: 'mock_token_' + amount
        }
    };
};

const sendTranzilaHandshakeRequest = async (amount) => {
    const url = `${BASE_URL}${API_TRANZILA_HANDSHAKE_ENDPOINT}`;
    const token = localStorage.getItem('userToken');
    //if (!token) {
    //    return handleErrorResponse('There is no user token', 401);
    //}
    return axios.post(url, { amount }, {
        headers: {
            "Authorization": `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        validateStatus: status => status < 500
    });
};

const processSuccessfulResponse = (response) => {
    if (!response.data || !response.data.data || typeof response.data.data !== 'object' || !response.data.data.token) {
        return handleErrorResponse('Invalid data format from server', 500);
    }
    return {
        status: 200,
        data: {
            token: response.data.data.token
        }
    };
};

const handleErrorResponse = (errorMessage, errorStatus) => {
    console.warn('Tranzila handshake error:', errorMessage);
    return { status: errorStatus, data: { error: errorMessage } };
};

const performTranzilaHandshake = async (amount) => {
    if (IS_TEST_MODE) {
        return getMockTranzilaHandshakeData(amount);
    }
    try {
        const response = await sendTranzilaHandshakeRequest(amount);
        if (response.status === 200) {
            return processSuccessfulResponse(response);
        } else {
            return handleErrorResponse(response.data.error, response.status);
        }
    } catch (error) {
        return handleErrorResponse(error.message || 'Unknown error occurred', error.response ? error.response.status : 500);
    }
};

export default performTranzilaHandshake;