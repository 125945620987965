// getSystemLanguage.js
import { useUser } from '../context/UserContext';

const getSystemLanguage = () => {
    //const languageCode = navigator.language.split('-')[0];
    let language = 'English'; // Значение по умолчанию

    //if (languageCode === 'ru') {
    //    language = 'Русский';
    //} else if (languageCode === 'uk') {
    //    language = 'Український';
    //}

    return language;
};


export { getSystemLanguage };