import React, { useState, useEffect } from 'react';
import useTranzilaHandshake from '../../hooks/useTranzilaHandshake';
import './TranzilaPaymentForm.css'; // Импортируйте файл стилей
import '../../styles/inputStyles.css';
import appConfig from '../../static/json/appConfig.json';

import { useUser } from '../../context/UserContext';

const TranzilaPaymentForm = ({ amount, period, plan }) => {
    const { user } = useUser();
    const commonText = appConfig[user.language]["common"];

    const { getHandshakeToken, loading } = useTranzilaHandshake();
    const [token, setToken] = useState('');
    const [error, setError] = useState(null);

    const tranzilaUrl = "https://direct.tranzila.com/jira/iframenew.php";
    const tranzilaUrl2 = "https://direct.tranzila.com/astrology/iframenew.php";

    const trBgColor = "#000000";
    const trTextColor = "#fffba7";
    const trButtonColor = "#9593FB";


    useEffect(() => {
        const fetchToken = async () => {
            const result = await getHandshakeToken(amount);
            if (result.success) {
                setToken(result.token);
            } else {
                setError(result.error);
            }
        };
        fetchToken();
    }, [amount, getHandshakeToken]);

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!token) {
            alert('Ожидание получения токена. Пожалуйста, подождите.');
            return;
        }

        // Отправка формы
        document.getElementById('payment-form').submit();
    };

    // Определение языков, поддерживаемых Tranzila
    const tranzilaLanguageMap = {
        "de": "de",
        "en": "en",
        "es": "es",
        "fr": "fr",
        "it": "en",
        "pl": "en",
        "pt": "en",
        "ru": "ru",
        "tr": "en",
        "uk": "en"
    };

    // Определение языка для передачи в Tranzila
    const lang = tranzilaLanguageMap[user.language] || "en";

    const productData = [
        {
            product_name: plan,
            product_quantity: 1,
            product_price: amount
        }
    ];
    const purchaseData = JSON.stringify(productData);
    const encodedPurchaseData = encodeURIComponent(purchaseData);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (

        <form id="payment-form" action={tranzilaUrl2} method="POST" onSubmit={handleSubmit}>
            <input type="hidden" name="sum" value={amount} />
            <input type="hidden" name="currency" value="2" />
            <input type="hidden" name="buttonLabel" value="Pay" />
            <input type="hidden" name="cred_type" value="1" />
            <input type="hidden" name="google_pay" value="1" />
            <input type="hidden" name="tranmode" value="AK" />
            <input type="hidden" name="nologo" value="0" />
            <input type="hidden" name="trBgColor" value={trBgColor} />
            <input type="hidden" name="trTextColor" value={trTextColor} />
            <input type="hidden" name="trButtonColor" value={trButtonColor} />
            <input type="hidden" name="recur_payments" value={period === 'monthly' ? 4 : 7} />
            <input type="hidden" name="recur_transaction" value={period === 'monthly' ? 4 : 7} />
            <input type="hidden" name="recur_sum" value={amount} />
            <input type="hidden" name="recur_start_date" value={new Date().toISOString().split('T')[0]} />
            <input type="hidden" name="success_url_address" value="https://yourwebsite.com/success" />
            <input type="hidden" name="fail_url_address" value="https://yourwebsite.com/failure" />
            <input type="hidden" name="thtk" value={token || ''} />

            {/* Добавление данных пользователя */}
            <input type="hidden" name="contact" value={user.name} />
            <input type="hidden" name="email" value={user.email} />

            {/* Параметры для инвойсов */}
            <input type="hidden" name="pdesc" value="Subscription to informational services" />
            <input type="hidden" name="company" value="Astrology AI" />
            <input type="hidden" name="remarks" value="" />
            <input type="hidden" name="json_purchase_data" value={encodedPurchaseData} />
            <input type="hidden" name="u71" value="1" />

            {/* Параметр языка */}
            {lang !== "en" && <input type="hidden" name="lang" value={lang} />}

            <button type="submit" className="submit-button">
                <span className="submit-text">{commonText["Pay"]}</span>
            </button>
        </form>

    );
};

export default TranzilaPaymentForm;
