import React, { useState } from 'react';
import Container from '../common/Container';
import SocialLogin from '../socialLogin/SocialLogin';
import PromptWithActionLink from '../common/PromptWithActionLink';
import SignUpForm from './SignUpForm';
import { useRegistration } from '../../hooks/useRegistration';

import { useUser } from '../../context/UserContext';
import appConfig from '../../static/json/appConfig.json';
import '../../styles/inputStyles.css';
import './SignUp.css';
import useBackHandler from '../../hooks/useBackHandler';

const SignUp = ({ goToSignIn, goToTerms, goToVerification, goToProfile, goToPrediction, onBack}) => {
    console.log('SignUp is rendered');

    const { termsAccepted} = useState(false)
    const { user, setUser } = useUser();
    const [authError, setAuthError] = useState(null);
    const [initialValues, setInitialValues] = useState({
        name: user.name,
        email: user.email,
        password: user.password,
        agreeToTerms: user.agreeToTerms
    });
    const { registerUser, loading } = useRegistration();

    const commonText = appConfig[user.language]["common"];

    const onSubmit = async (values) => {
        console.log("onSubmit - ", values);
        setUser(prevUser => ({
            ...prevUser,
            name: values.name,
            email: values.email,
            password: values.password,
            agreeToTerms: values.agreeToTerms
        }));

        const response = await registerUser(values);

        if (response.success) {
            if (response.status === 200 )
            {
                if (response.user && response.user.is_registration_completed) {
                    if (!user.is_registration_completed) {
                        goToProfile();
                    }
                    else {
                        goToPrediction();
                    }
                }
            }
            else if (response.status === 201) {
                goToVerification();
            }
        } else {
            setAuthError(response.error);
            setInitialValues(values);
        }

        
    };

    useBackHandler(onBack);

    if (loading) {
        return (
            <div className="center">
                <div className="loader"></div>
            </div>
        );
    }

    return (
        <div className="overlay parent-centered-div signup-overlay-height">
            <div className="centered-div">
                <h2 className="titleText signup-titleText">
                    {commonText["Sign Up"]}
                </h2>

                {authError && <div className="errorText">{authError}</div>}
                <div className="signup-item-container" >
                    <SignUpForm
                        initialValues={initialValues}
                        termsAccepted={termsAccepted}
                        goToTerms={goToTerms}
                        onSubmit={onSubmit}
                    />
                </div>
                <div className="signup-item-container" >
                    <SocialLogin
                        
                    />

                </div>
                <div className="signup-item-container" >
                    <PromptWithActionLink
                        prompt={commonText["Have an account?"]}
                        buttonText={commonText["Sign In"]}
                        onLinkPress={goToSignIn}
                    />
                </div>
            </div>
        </div>);
};

export default SignUp;