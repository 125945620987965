import React from 'react';
import './LanguageSelector.css'; // Импортируем CSS-файл с нужными стилями

// Импортируем изображение
const images = {
    "Welcome": require('../../static/image/earth.png')
};

const LanguageSelector = ({ onSubmit, onBack }) => {
    return (
        <div className="language-selector-container">
            <button
                onClick={onSubmit}
                style={{
                    backgroundImage: `url(${images["Welcome"]})`
                }}
                className="language-selector-button"
                title="Select language"
            >
                {/* Можно добавить текст или иконку, если нужно */}
            </button>
        </div>
    );
};

export default LanguageSelector;