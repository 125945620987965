import React from 'react';
import './BackgroundImage.css'; // Импортируйте CSS-файл

const BackgroundImage = ({ children }) => {
    return (
        <div className="background">
            {children}
        </div>
    );
};

export default BackgroundImage;