import axios from 'axios';
import { IS_TEST_MODE, BASE_URL, API_USER_INFO_ENDPOINT } from '../config/config';

const getMockUserInfoData = (user_info) => {
    return {
        status: 200,
        data: {
            user_info: user_info
        }
    };
};

const sendUserInfoRequest = async (user_info) => {
    const url = `${BASE_URL}${API_USER_INFO_ENDPOINT}`;
    const token = localStorage.getItem('userToken');

    if (!token) {
        return handleErrorResponse('There is no user token', 401);
    }

    return axios.post(url, user_info, {
        headers: {
            "Authorization": `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        validateStatus: status => status < 500
    });
};

const processSuccessfulResponse = (response) => {
    if (!response.data || !response.data.data || typeof response.data.data !== 'object') {
        return handleErrorResponse('Invalid data format from server', 500);
    }

    return {
        status: 200,
        data: response.data.data
    };
};

const handleErrorResponse = (errorMessage, errorStatus) => {
    console.warn('User info error:', errorMessage);
    return { status: errorStatus, data: { error: errorMessage } };
};

const sendUserInfoToServer = async (user_info) => {
    if (IS_TEST_MODE) {
        return getMockUserInfoData(user_info);
    }

    try {
        const response = await sendUserInfoRequest(user_info);

        if (response.status === 200) {
            return processSuccessfulResponse(response);
        } else {
            return handleErrorResponse(response.data.error, response.status);
        }
    } catch (error) {
        return handleErrorResponse(error.message || 'Unknown error occurred', error.response ? error.response.status : 500);
    }
};

export default sendUserInfoToServer;

