import axios from 'axios';
import google_client_config from '../static/json/google_client_config.json';
import { IS_TEST_MODE } from '../config/config';

export const googleService = async () => {
    console.log('googleService IS_TEST_MODE', IS_TEST_MODE);
    if (IS_TEST_MODE) {
        return new Promise(resolve => setTimeout(() => {
            resolve({
                status: 200,
                data: { token: 'mock_access_token' }
            });
        }, 500));
    }

    try {
        const config = {
            responseType: 'token',
            client_id: google_client_config.installed.client_id,
            scope: 'profile email',
            redirect_uri: window.location.origin,
        };
        console.log('googleService config', config);

        const params = new URLSearchParams(config).toString();
        const googleAuthUrl = `https://accounts.google.com/o/oauth2/v2/auth?${params}`;
        console.log('googleService url', googleAuthUrl);

        window.location.href = googleAuthUrl;

        return new Promise((resolve, reject) => {
            window.addEventListener('message', (event) => {
                if (event.origin === window.location.origin) {
                    resolve({
                        status: 200,
                        data: { token: event.data.token }
                    });
                } else {
                    reject(new Error('Invalid origin'));
                }
            });
        });


    } catch (error) {
        console.log('googleService error', error);
        return {
            status: 500,
            data: { error: error.message }
        };
    }
};