import React, { useState, useRef, useImperativeHandle, forwardRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../../styles/inputStyles.css';
import colors from '../../styles/colors';
import "./TimeInput.css"

function formatDate(date) {
    if (!date) return 'dd-mm-yyyy';
    const d = new Date(date);
    let day = d.getDate().toString();
    let month = (d.getMonth() + 1).toString();
    let year = d.getFullYear().toString();

    day = day.length < 2 ? '0' + day : day;
    month = month.length < 2 ? '0' + month : month;

    return `${day}-${month}-${year}`;
}

function formatDateWithMonthName(date) {
    if (!date) return 'dd-mon-yyyy';
    const d = new Date(date);
    let day = d.getDate().toString();
    let month = d.toLocaleString('en-US', { month: 'short' });
    let year = d.getFullYear().toString();

    day = day.length < 2 ? '0' + day : day;

    return `${day}-${month}-${year}`;
}

function formatDateYYYYMMDD(date) {
    if (!date) return 'yyyy-mm-dd';
    const d = new Date(date);
    let day = d.getDate().toString();
    let month = (d.getMonth() + 1).toString();
    let year = d.getFullYear().toString();

    day = day.length < 2 ? '0' + day : day;
    month = month.length < 2 ? '0' + month : month;

    return `${year}-${month}-${day}`;
}

const DateInput = forwardRef(({name,  label, field, form, setRef, removeFocusFromAll, nextFieldFocus }, ref) => {
    console.log("DateInput is rendered")

    const [selectedDate, setSelectedDate] = useState(new Date(field.value || new Date()));
    const [isFocused, setFocused] = useState(false);
    const dateInputRef = useRef(null);

    useImperativeHandle(setRef(name), () => ({
        removeFocus: () => {
            setFocused(false);
        },
        isFocused: () => {
            return isFocused;
        },
        SetFocus: () => {
            console.log("DateInput SetFocus called")
            setFocused(true);
            dateInputRef.current.input.click(); 
        }, 
        myUniqueId: name
    }), [ isFocused, name]);

    const handleChange = (selectedDate) => {
        if (selectedDate) {
            setSelectedDate(selectedDate);
            const formattedDate = formatDateYYYYMMDD(selectedDate);
            form.setFieldValue(field.name, formattedDate);
            form.setFieldTouched(field.name, true);
        }
    };

    const handleBlur = () => {
         setTimeout(() => {
            nextFieldFocus(name);
            console.log("Next field is focused:");
        }, 0);
        console.log("Next field is focused:")
    };

    /*console.log("DateInput is focused:", isFocused)*/

    return (
        <div className="container">
            <label className="text">{label}</label>
            <div
                onBlur={() => {
                    setFocused(false);
                    form.handleBlur(field.name);
                }}
                className={`border ${isFocused ? 'focused' : ''} parent-centered-div`}
            >
                <DatePicker
                    selected={selectedDate}
                    onChange={(date) => {
                        handleChange(date);
                        handleBlur();  // Перенос вызова handleBlur сюда
                    }}
                    onInputClick={() => {
                        removeFocusFromAll(name);
                        setFocused(true);
                    }}
                    onBlur={() => {
                        setFocused(false);
                        form.handleBlur(field.name);
                    }}
                    ref={dateInputRef}
                    dateFormat="dd-mm-yyyy"
                    className="dateinput-date-picker"
                    calendarClassName="dateinput-date-calendar"
                    popperClassName="dateinput-date-popper"
                    placeholderText="dd-mm-yyyy"
                    showYearDropdown
                    scrollableYearDropdown // Добавляет скроллинг в выпадающем списке годов
                    yearDropdownItemNumber={100}
                    customInput={
                        <div className={`text dateinput-text ${!selectedDate ? 'placeholder-text-color' : ''}`}>
                            {formatDateWithMonthName(selectedDate)}
                        </div>
                    }
                />
            </div>
            {form.touched[field.name] && form.errors[field.name] ? (
                <div className="error-text">{form.errors[field.name]}</div>
            ) : null}
        </div>
    );
});

export default DateInput;

