// useVerification.js
import { useState, useCallback } from 'react';
import { veryficateOnServer } from '../services/veryficateOnServer';

import useUserState from './useUserState';

// Hook для использования функции верификации
export const useVerification = () => {
    const [loading, setLoading] = useState(false);
    const { updateUserData } = useUserState();

    const verifyUser = useCallback(async ({ name, email, password, verification, language }) => {
        setLoading(true);
        try {
            const response = await veryficateOnServer({ name, email, password, verification, language });

            if (response.status === 200 && !response.data.error) {
                return { success: true };
            } else {
                return { success: false, error: response.data.error };
            }
        } catch (error) {
            return { success: false, error: error.message || 'Registration error' };
        } finally {
            setLoading(false);
        }
    }, [updateUserData]);

    return {
        verifyUser,
        loading
    };
};

//export const useVerification = () => {
//    const { user, setUser } = useUser();
//    const [loading, setLoading] = useState(false);

//    const verifyUser = useCallback(async ({ name, email, password, verification, language }) => {
//        try {
//            setLoading(true);
//            const response = await veryficateOnServer({
//                name: name,
//                email: email,
//                password: password,
//                verification: verification,
//                language: language,
//            });

//            if (response && response.data && response.status === 200) {
//                localStorage.setItem('userToken', response.data.token);

//                setUser(prevUser => ({
//                    ...prevUser,
//                    ...response.data.user,
//                }));
//                return { success: true };
//            } else {
//                return { success: false, error: response?.data?.error || 'Registration error' };
//            }
//        } catch (error) {
//            return { success: false, error: error.message || 'Registration error' };
//        } finally {
//            setLoading(false);
//        }
//    }, [user, setUser]);

//    return {
//        verifyUser,
//        loading
//    };
//};