// Profile.js
import React from 'react';
import '../../styles/inputStyles.css';
import '../../styles/colors.css';
import useBackHandler from '../../hooks/useBackHandler';

import ProfileForm from "./ProfileForm"

const Profile = ({ onSubmit, onBack }) => {
    useBackHandler(onBack);

    return (
        < div className="overlay parent-centered-div" >
            <div className="centered-div">
                <ProfileForm onSubmit={onSubmit} />
            </div>
        </div>
    );
};

export default Profile;