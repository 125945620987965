// VerificationSquare.js
import React from 'react';

import './VerificationSquare.css';
import '../../styles/inputStyles.css';

const VerificationSquare = ({ field, form, nextInput, innerRef }) => {

    const handleChange = (text) => {
        if (text.length === 1) {
            form.setFieldValue(field.name, text);
            if (nextInput && nextInput.current) {
                nextInput.current.focus();
            }
        } else if (text.length === 0) {
            form.setFieldValue(field.name, '');
        }
    };

    return (
        <div className="verificationsquare-rectangleview">
            <input
                className="verificationsquare-textInput"
                ref={innerRef}
                maxLength={1}
                type="text"
                pattern="\d*"
                onChange={e => handleChange(e.target.value)}
                value={field.value}
            />
        </div>
    );
};

export default VerificationSquare;