import { useEffect } from 'react';

// Создание кастомного хука useBackHandler
const useBackHandler = (onBackPress) => {
    useEffect(() => {
        // Функция обработки нажатия кнопки "Назад"
        const backAction = (event) => {
            onBackPress();
            event.preventDefault(); // Предотвращение действия по умолчанию
            return false;
        };

        // Подписываемся на событие нажатия кнопки "Назад" в браузере
        window.history.pushState(null, document.title, window.location.href);
        window.addEventListener('popstate', backAction);

        // Очистка подписки
        return () => window.removeEventListener('popstate', backAction);
    }, [onBackPress]); // Перезапускать эффект, если функция onBackPress изменится
};

export default useBackHandler;