import { useState, useEffect, useRef } from 'react';

export const useScreenOffsetControl = () => {
    const [screenOffset, setScreenOffset] = useState(0);
    const currentScreenOffsetRef = useRef(0);
    const maxScreenOffsetRef = useRef(0);
    const dynamicContentHeightRef = useRef(0);
    const standardContextHeightRef = useRef(0);
    const keyboardHeightRef = useRef(0); // В веб-версии это значение будет использоваться по-другому
    const keyboardVisibleRef = useRef(false); // В веб-версии обработка видимости клавиатуры не требуется
    const inputFieldOffset = useRef(0);

    // Обновление высоты динамического контента
    const updateContentHeight = (newHeight) => {
        // console.log("updateContentHeight");
        if (Math.abs(dynamicContentHeightRef.current - newHeight) > 0.5) {
            dynamicContentHeightRef.current = newHeight;
            if (standardContextHeightRef.current == 0) {
                standardContextHeightRef.current = newHeight;
            }
            recalculateScreenOffset();
        }
    };

    const updateScreenOffset = (value) => {
        setScreenOffset(value);
        currentScreenOffsetRef.current = value;
    };

    // Пересчет смещения экрана
    const recalculateScreenOffset = () => {
        // В веб-приложениях эта функция может быть использована для изменения положения скролла или для адаптации макета
        // console.log("recalculateScreenOffset");
        // LayoutAnimation.easeInEaseOut(); // В React.js аналогичный эффект может быть достигнут через CSS transitions

        const baseValue = standardContextHeightRef.current - dynamicContentHeightRef.current;
        const delta = baseValue - (keyboardVisibleRef.current ? keyboardHeightRef.current : 0);

        maxScreenOffsetRef.current = delta;

        let correctedValue = baseValue + (keyboardVisibleRef.current ? inputFieldOffset.current : 0);
        if (inputFieldOffset.current <= 0) {
            correctedValue = baseValue + (keyboardVisibleRef.current ? inputFieldOffset.current : 0);
        } else if (baseValue < 0) {
            correctedValue = 0;
        }

        updateScreenOffset(correctedValue);

        //Выводим отладочную информацию
        //console.log(
        //     "recalculateScreenOffset: keyboardVisible =", keyboardVisibleRef.current,
        //     "\n                         dynamicContentHeightRef =", dynamicContentHeightRef.current,
        //     "\n                         standartContextHightRef =", standardContextHeightRef.current,
        //     "\n                         baseValue =", baseValue,
        //     "\n                         Delta =", delta,
        //     "\n                         CorrectedValue =", correctedValue,
        //     "\n                         MaximalScreenOffset =", maxScreenOffsetRef.current,
        //     "\n                         ScreenOffset =", currentScreenOffsetRef.current
        // );
    };

    // Установка смещения поля ввода
    const setFieldOffset = (value) => {
        inputFieldOffset.current = value;
        recalculateScreenOffset();
    };

    // Адаптация обработчика жестов для веба
    useEffect(() => {
        let previousOffset = 0;

        const handleMouseMove = (event) => {
            // В React.js gestureState.dy можно заменить на event.movementY
            let newOffset = Math.min(0, Math.max(previousOffset + event.movementY, maxScreenOffsetRef.current));
            updateScreenOffset(newOffset);
        };

        const handleMouseDown = () => {
            previousOffset = currentScreenOffsetRef.current;
            document.addEventListener('mousemove', handleMouseMove);
        };

        const handleMouseUp = () => {
            document.removeEventListener('mousemove', handleMouseMove);
        };

        document.addEventListener('mousedown', handleMouseDown);
        document.addEventListener('mouseup', handleMouseUp);

        return () => {
            document.removeEventListener('mousedown', handleMouseDown);
            document.removeEventListener('mouseup', handleMouseUp);
            document.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);

    // Адаптация слушателя клавиатуры для веба
    useEffect(() => {
        const handleFocus = (event) => {
            // В вебе нет прямого аналога keyboardDidShow, но можно обрабатывать фокус
            inputFieldOffset.current = event.target.offsetTop; // Пример получения смещения поля ввода
            recalculateScreenOffset();
        };

        const handleBlur = () => {
            // В вебе нет прямого аналога keyboardDidHide, используем blur для сброса смещения
            updateScreenOffset(0); // Сброс смещения при потере фокуса
        };

        window.addEventListener('focus', handleFocus, true);
        window.addEventListener('blur', handleBlur, true);

        return () => {
            window.removeEventListener('focus', handleFocus, true);
            window.removeEventListener('blur', handleBlur, true);
        };
    }, []);

    // Возвращаем все необходимые параметры и методы
    return [screenOffset, setFieldOffset, updateContentHeight];
};