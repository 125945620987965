import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import { Field } from 'formik';

import '../../styles/inputStyles.css'; // Предполагается, что стили inputStyles уже определены в CSS
import './RadioGroup.css'; 

const RadioGroup = forwardRef((props, ref) => {
    const { label, options, removeFocusFromAll, name, validate, onSelect, form, initialValue, nextFieldFocus, setRef } = props;
    const [isFocused, setFocused] = useState(false);
    const [inputValue, setInputValue] = useState(initialValue || '');

    useImperativeHandle(setRef(name), () => ({
        removeFocus: () => {
            setFocused(false);
        },
        isFocused: () => {
            return isFocused;
        },
        SetFocus: () => {
            setFocused(true);
        },
        myUniqueId: name
    }), [isFocused, name]);

    useEffect(() => {
        setInputValue(initialValue || '');
    }, [initialValue, options]);

    const handleBlur = () => {
        setFocused(false);
        nextFieldFocus(name);
    };

    const handleRadioChange = (event) => {
        const value = event.target.value;
        setInputValue(value);
        onSelect(value);
        nextFieldFocus(name);
    };

    const handleFocus = () => {
        removeFocusFromAll(name);
        setFocused(true);
    };

    return (
        <Field name={name} >
            {({ field, form }) => (
                <div className="container">
                    <label className="titleText">{label}</label>
                    <div className={`radio-container`}>
                        {options.map((option, index) => (
                            <label key={index}>
                                <input
                                    type="radio"
                                    {...field}
                                    value={option.value}
                                    className="radio-selector "
                                    checked={field.value === option.value}
                                    onChange={handleRadioChange}
                                    onFocus={() => {
                                        removeFocusFromAll(name);
                                        setFocused(true);
                                    }}
                                />
                                <span className="text radio-label">
                                    {option.label}
                                </span>
                            </label>
                        ))}
                    </div>
                    {form.touched[field.name] && form.errors[field.name] && (
                        <div className="errorText">{form.errors[field.name]}</div>
                    )}
                </div>
            )}
        </Field>
    );
});


export default RadioGroup;
