import React, { useState, useImperativeHandle, forwardRef, useRef } from 'react';
import { Field } from 'formik';
import '@fortawesome/fontawesome-free/css/all.min.css';

import '../../styles/inputStyles.css'; // Убедитесь, что стили определены в CSS
import './CustomInput.css'; // Убедитесь, что стили определены в CSS

const CustomInput = React.forwardRef(({ name, label, validate, placeholder, form, type, initialValue, setRef, removeFocusFromAll, nextFieldFocus }, ref) => {
    console.log("CustomInput is rendered")

    const [isFocused, setFocused] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const dateInputRef = useRef(null);

    useImperativeHandle(setRef(name), () => ({
        removeFocus: () => {
            setFocused(false);
        },
        isFocused: () => {
            return isFocused;
        },
        SetFocus: () => {
            setFocused(true);
            dateInputRef.current.focus();
        },
        myUniqueId: name
    }), [isFocused, name]);

    const handleBlur = () => {
        setFocused(false);
        nextFieldFocus(name);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleBlur();
        }
    };

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <Field name={name} validate={validate}>
            {({ field, form }) => (
                <div className="container custom-input-container">
                    <label className="text input-label">{label}</label>
                    <div className="custom-input-input-wrapper">
                        <input
                            className={`text border custom-input-field ${isFocused ? 'focused' : ''}`}
                            placeholder={placeholder}
                            name={field.name}
                            value={field.value}
                            onChange={form.handleChange(field.name)}
                            onBlur={() => {
                                setFocused(false);
                                form.handleBlur(field.name);
                            }}
                            onFocus={() => {
                                removeFocusFromAll(name);
                                setFocused(true);
                            }}
                            onKeyDown={handleKeyDown}
                            ref={dateInputRef}
                            type={type === 'password' && !showPassword ? 'password' : 'text'}
                        />
                        {type === 'password' && (
                            <button
                                type="button"
                                onClick={toggleShowPassword}
                                className="custom-input-toggle-password"
                                aria-label="Toggle password visibility"
                            >
                                <i className={`fas ${showPassword ? 'fa-eye-slash' : 'fa-eye'}`} />
                            </button>
                        )}
                    </div>
                    {form.errors[field.name] && (
                        <div className="errorText">{form.errors[field.name]}</div>
                    )}
                </div>
            )}
        </Field>
    );
});

export default CustomInput;