import React, { useState } from 'react';
import '../../styles/inputStyles.css'; // Правильный импорт общего CSS файла
import useBackHandler from '../../hooks/useBackHandler';

import appConfig from '../../static/json/appConfig.json';
import CustomForm from '../common/CustomForm';
import { componentInstaller } from '../../utils/componentInstaller';
import useFocusManagement from '../../hooks/useFocusManagement';
import { useUser } from '../../context/UserContext';
import { useSendUserInfo } from '../../hooks/useSendUserInfo';
import '../../styles/inputStyles.css';
import './LanguageForm.css'

const LanguageForm = ({ onSubmit, onBack }) => {
    const { user, setUser } = useUser();
    const { sendUserInfo, loading } = useSendUserInfo();
    const [authError, setAuthError] = useState(null);

    // Загрузка метаданных полей из JSON
    const fieldMetadataArray = appConfig[user.language]["languageMetadataArray"];
    const commonText = appConfig[user.language]["common"];

    const languageList = appConfig["languageList"].map(item => ({
        "label": item.name,
        "value": item.name
    }));

    const onSubmitForm = async (values) => {
        if (!user.isAuthenticated) {
            setUser({ ...user, language: values.language });
            onSubmit();
        }
        else {
            console.log('LanguageForm onSubmitForm values:', values);
            const response = await sendUserInfo({ language: values.language });
            console.log('LanguageForm onSubmitForm response:', response);

            if (!response.success) {
                setAuthError(response.error);
            } else {
                onSubmit();
            }
        }
    };

    // Создание массива идентификаторов ссылок
    const refIdentifiers = fieldMetadataArray.map(item => item.name);

    // Хуки для управления фокусом
    const { setRef, removeFocusFromAll, nextFieldFocus } = useFocusManagement(refIdentifiers);

    useBackHandler(onBack);

    if (loading) {
        return <div className="centered-container">
            <div className="loading-spinner"></div>
        </div>;
    }

    const fieldsConfig = fieldMetadataArray.map(metadata => ({
        ...metadata,
        //component: getComponentByName(metadata.name),
        component: componentInstaller(metadata.component),
        additionalProps: {
            options: languageList,
        }
    }));

    return (
        <div className="overlay bottom-div parent-centered-div">
            <div className="centered-div">
                <CustomForm
                    fieldsConfig={fieldsConfig}
                    initialValues={{
                        language: user.language,
                    }}
                    setRef={setRef}
                    removeFocusFromAll={removeFocusFromAll}
                    nextFieldFocus={nextFieldFocus}
                    onSubmit={onSubmitForm}
                    submitText={commonText["Select"]}
                />
                {authError && <div className="error-text">{authError}</div>}
            </div>
        </div>
    );
};

export default LanguageForm;
