import React, { useState, useEffect } from 'react';
import './Menu.css';

import ChangePasswordForm from '../ChangePassword/ChangePassword';
import Profile from '../profile/Profile';
import ContentSelector from '../ContentSelector/ContentSelector';
import ContactSettings from '../ContactSettings/ContactSettings';
import Subscription from '../subscription/Subscription';
import { useUser } from '../../context/UserContext';
import logout from '../../services/logout';
import useBackHandler from '../../hooks/useBackHandler';

const Menu = ({ onBack, goToGreetingForm, isInsideMenu, setIsInsideMenu }) => {
    const [selectedMenu, setSelectedMenu] = useState(null);
    const { user, setUser, resetUser } = useUser();

    const menuNames = require('../../static/json/appConfig.json')[user.language]["menuNames"];

    useEffect(() => {
        if (!isInsideMenu) {
            setSelectedMenu(null);
            setIsInsideMenu(true);
        }
    });

    const onSubmitProfile = () => {
        setSelectedMenu('Profile');
    };

    const onSubmitChangePassword = () => {
        setSelectedMenu('Change password');
    };

    const onSubmitContactSettings = () => {
        setSelectedMenu('Contact Settings');
    };

    const onSubmitContent = () => {
        setSelectedMenu('Content');
    };

    const onSubmitSubscription = () => {
        setSelectedMenu('Subscription');
    };

    const onSubmitLogout = async () => {
        console.log('Log out button clicked');
        setSelectedMenu('Logout');
        await logout();
        resetUser(); 
        goToGreetingForm();
    };

    const resetMenu = () => {
        if (selectedMenu != null)
            setSelectedMenu(null);
        else
            onBack();
    };

    useBackHandler(resetMenu);

    return (
        <div className="menu-container">
            {selectedMenu === null && (
                <>
                    <button className="menu-button" onClick={onSubmitProfile}>
                        <span className="menu-text">
                            {menuNames["Profile"]}
                        </span>
                    </button>
                    <button className="menu-button" onClick={onSubmitContent}>
                        <span className="menu-text">
                            {menuNames["Content"]}
                        </span>
                    </button>
                    <button className="menu-button" onClick={onSubmitContactSettings}>
                        <span className="menu-text">
                            {menuNames["Contact Settings"]}
                        </span>
                    </button>
                    <button className="menu-button" onClick={onSubmitSubscription}>
                        <span className="menu-text">
                            {menuNames["Subscription"]}
                        </span>
                    </button>
                    <button className="menu-button" onClick={onSubmitChangePassword}>
                        <span className="menu-text">
                            {menuNames["Change password"]}
                        </span>
                    </button>
                    <button className="menu-button" onClick={onSubmitLogout}>
                        <span className="menu-text">
                            {menuNames["Log out"]}
                        </span>
                    </button>
                </>
            )}
            {selectedMenu === 'Contact Settings' && <ContactSettings onSubmit={resetMenu} onBack={resetMenu} />}
            {selectedMenu === 'Change password' && <ChangePasswordForm onSubmit={resetMenu} onBack={resetMenu} />}
            {selectedMenu === 'Profile' && <Profile onSubmit={resetMenu} onBack={resetMenu} />}
            {selectedMenu === 'Content' && <ContentSelector onSubmit={resetMenu} onBack={resetMenu} />}
            {selectedMenu === 'Subscription' && <Subscription onSubmit={resetMenu} onBack={resetMenu} header = "false" />}
        </div>
    );
};

export default Menu;
