// validationSchema.js
import * as Yup from 'yup';
import appConfig from '../../static/json/appConfig.json';

export const makeValidationSchema = (userLanguage) => {
    const commonText = appConfig[userLanguage]["common"];

    return Yup.object({
        useEmail: Yup.boolean(),
        useWhatsApp: Yup.boolean(),
        useTelegram: Yup.boolean(),
        usePushMessages: Yup.boolean(),
        phone: Yup.string()
            .matches(/^\[+\]?\[0-9\]{10,15}$/, commonText['Please enter a valid phone number'])
            .when(['whatsapp', 'telegram'], {
            is: (whatsapp, telegram) => whatsapp || telegram,
            then: (schema) => schema
                .required(commonText['Phone number is required for WhatsApp or Telegram']),
            otherwise: (schema) => schema,
        })
    });
};