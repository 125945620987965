import axios from 'axios';
import { IS_TEST_MODE, BASE_URL } from '../config/config';

export const authenticateSocialOnServer = async (data) => {
    const { socialNetwork, token } = data;

    const endpoint = '/api/authenticateSocial/';
    const url = `${BASE_URL}${endpoint}`;

    if (IS_TEST_MODE) {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve({
                    status: 200,
                    data: {
                        token: 'fake-server-token',
                        user: {
                            is_registration_completed: true,
                            astrobot: "Bruce",
                            language: 'Русский',
                            generalContent: true,
                            businessContent: true,
                            relationContent: true,
                            healthContent: false,
                            gender: "male",
                            birth_date: "1966-09-04",
                            birth_time: "00:53:28",
                            birth_country: "Ukraine",
                            birth_city: "Kharkiv",
                            biography: '',
                            subscriptionType: 'Premium',
                            subscriptionPerMonth: 0,
                            subscriptionPerYear: 0,
                        }
                    }
                });
            }, 500);
        });
    } else {
        try {
            const response = await axios.post(url, { socialNetwork, token });
            return {
                status: response.status,
                data: response.data
            };
        } catch (error) {
            return {
                status: 500,
                data: { error: error.message }
            };
        }
    }
};