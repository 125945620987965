import React from 'react';
import { UserProvider } from './context/UserContext';
import MainPage from './components/MainPage/MainPage';



function App() {
    return (
        <UserProvider>
            <MainPage />
        </UserProvider>
    );
}

export default App;
