import axios from 'axios';
import { IS_TEST_MODE, BASE_URL, API_PASSWORD_RESET_ENDPOINT } from '../config/config';

const getMockPasswordResetData = () => {
    return {
        status: 200,
        data: {
            message: 'Mock password reset email sent.'
        }
    };
};

const handleErrorResponse = (errorMessage, errorStatus) => {
    console.warn('Password reset error:', errorMessage);
    return { status: errorStatus, data: { error: errorMessage } };
};

const sendPasswordResetRequest = async (username) => {
    if (IS_TEST_MODE) {
        return getMockPasswordResetData();
    }

    try {
        const url = `${BASE_URL}${API_PASSWORD_RESET_ENDPOINT}`;
        const response = await axios.post(url, { username }, {
            validateStatus: status => status < 500
        });

        if (response.status === 200) {
            return response;
        } else {
            return handleErrorResponse(response.data.error, response.status);
        }
    } catch (error) {
        return handleErrorResponse(error.message || 'Unknown error occurred', error.response ? error.response.status : 500);
    }
};

export default sendPasswordResetRequest;
