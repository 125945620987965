import { useState, useCallback } from 'react';

import { useUser } from '../context/UserContext';

import { googleService } from '../services/googleService';
import { facebookService } from '../services/facebookService';
import { appleService } from '../services/appleService';
import { authenticateSocialOnServer } from '../services/authenticateSocialOnServer';

export const useSocialAuthentication = () => {
    const { setUser } = useUser();
    const [loading, setLoading] = useState(false);

    const authenticateSocial = useCallback(async (socialNetwork) => {
        setLoading(true);
        try {
            let responseSocial;
            if (socialNetwork === 'google') {
                responseSocial = await googleService();
            } else if (socialNetwork === 'facebook') {
                responseSocial = await facebookService();
            } else if (socialNetwork === 'apple') {
                responseSocial = await appleService();
            } else {
                throw new Error('Unsupported social network');
            }

            if (responseSocial && responseSocial.status == 200) {
                const responseServer = await authenticateSocialOnServer({
                    socialNetwork: socialNetwork,
                    token: responseSocial.data.token
                });

                if (responseServer && responseServer.data && responseServer.status === 200) {
                    localStorage.setItem('userToken', responseServer.data.token);

                    setUser(prevUser => ({
                        ...prevUser,
                        ...responseServer.data.user,
                    }));

                    if (responseServer.data.user.is_registration_completed) {
                        setUser(prevUser => ({
                            ...prevUser,
                            isAuthenticated: true
                        }));
                    }
                    return { success: true };
                } else {
                    return { success: false, error: responseServer?.data?.error || 'Authentication error' };
                }
            } else {
                return { success: false, error: responseSocial?.data?.error || 'Authentication error' };
            }
        } catch (error) {
            return { success: false, error: error.message || 'Authentication error' };
        } finally {
            setLoading(false);
        }
    }, [setUser, setLoading]);

    return {
        authenticateSocial,
        loading
    };
};