import { useState, useCallback } from 'react';
import fetchPromoCode from '../services/fetchPromoCode'; // Импортируем сервис для работы с промокодами

const usePromoCodeValidation = () => {
    // Состояние для отслеживания загрузки при проверке промокода
    const [loading, setLoading] = useState(false);
    // Состояние для хранения данных промокода
    const [promoCodeData, setPromoCodeData] = useState(null);
    // Состояние для хранения ошибок
    const [error, setError] = useState(null);

    // Функция для проверки промокода
    const validatePromoCode = useCallback(async (promoCode) => {
        // Активация индикатора загрузки
        setLoading(true);
        setError(null);
        setPromoCodeData(null);

        try {
            // Отправка запроса на сервер для проверки промокода
            const response = await fetchPromoCode(promoCode);

            if (response.status === 200 && !response.data.error) {
                // При успешной проверке обновляем данные промокода
                setPromoCodeData(response.data);
                return { success: true, data: response.data };
            } else {
                // В случае ошибок сервера обновляем состояние ошибки
                setError(response.data.error);
                return { success: false, error: response.data.error };
            }
        } finally {
            // Выключение индикатора загрузки независимо от результата запроса
            setLoading(false);
        }
    }, []);

    // Возвращаем функцию проверки промокода, состояние загрузки, данные промокода и ошибки для использования в компонентах
    return {
        validatePromoCode,
        loading,
        promoCodeData,
        error
    };
};

export default usePromoCodeValidation;
