import { useState, useCallback } from "react";

import authenticateSignInOnServer from '../services/authenticateSignInOnServer';
import useUserState from './useUserState';

// Хук useSignInAuthentication предназначен для управления процессом аутентификации пользователя.
// Он инкапсулирует логику аутентификации, обеспечивая чистоту и переиспользование функционала в компонентах.
export const useSignInAuthentication = () => {
    // Состояние для отслеживания загрузки при аутентификации
    const [loading, setLoading] = useState(false); 

    // Получаем функцию обновления данных пользователя
    const { updateUserData } = useUserState();

    // Функция authenticateSignInUser выполняет аутентификацию пользователя с использованием переданных учетных данных.
    // Она асинхронно отправляет запрос на сервер и обрабатывает ответ.
    const authenticateSignInUser = useCallback(async ({ username, password }) => {

        // Активация индикатора загрузки
        setLoading(true);

        try {
            // Отправка запроса на сервер для аутентификации пользователя
            const response = await authenticateSignInOnServer({ username, password });

            if (response.status === 200 && !response.data.error) {

                // При успешной аутентификации обновляем данные пользователя и возвращаем успех.
                updateUserData(response.data.user);
                return { success: true, user: response.data.user };
            } else {

                // В случае ошибок сервера возвращаем объект с информацией об ошибке.
                return { success: false, error: response.data.error };
            }
        } finally {

            // Выключение индикатора загрузки независимо от результата запроса.
            setLoading(false);
        }
    }, [updateUserData]);

    // Возвращаем функцию аутентификации и состояние загрузки для использования в компонентах.
    return {
        authenticateSignInUser,
        loading
    };
};

