import React from 'react';
import './SubmitButton.css'; // Предположим, что стили перенесены в этот CSS файл

const SubmitButton = ({ text, onSubmit }) => {
    return (
        <div className="submit-buttonContainer">
            <button
                className="submit-button"
                onClick={() => {
                    onSubmit();
                }}
            >
                <span className="submit-text">{text}</span>
            </button>
        </div>
    );
};

export default SubmitButton;