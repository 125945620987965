import { useCallback, useState, useMemo, useRef } from 'react';

const useFocusManagement = (refIdentifiers) => {
    const refs = useRef({});

    const setRef = useCallback((id) => (node) => {
        refs.current[id] = node;
    }, []);


    const removeFocusFromAll = useCallback((exceptRefName) => {
        Object.entries(refs.current).forEach(([id, ref]) => {
            if (id !== exceptRefName && ref && ref.removeFocus) {
                ref.removeFocus();
            }
        });
    }, [refs]);

    const nextFieldFocus = useCallback((currentRefName) => {
        const currentRef = refs.current[currentRefName];
        if (currentRef && currentRef.removeFocus) {
            currentRef.removeFocus();
        }

        const currentIndex = refIdentifiers.indexOf(currentRefName);
        const nextIndex = (currentIndex + 1) % refIdentifiers.length;
        const nextRefName = refIdentifiers[nextIndex];
        const nextRef = refs.current[nextRefName];

        if (nextRef && nextRef.SetFocus) {
            nextRef.SetFocus();
        }
    }, [refs, refIdentifiers]);

    return { setRef, refs, removeFocusFromAll, nextFieldFocus };
};

export default useFocusManagement;