import React, { useState, useRef, useImperativeHandle, forwardRef } from 'react';
import { Field } from 'formik';

import '../../styles/inputStyles.css';  // Убедитесь, что стили определены в CSS
import './MultilineInput.css';  // Предполагается, что стили определены в MultilineInput.css

const MultilineInput = forwardRef(({ label, field, form, setRef, validate, removeFocusFromAll, name, placeholder, nextFieldFocus }, ref) => {
    const [isFocused, setFocused] = useState(false);

    useImperativeHandle(setRef(name), () => ({
        removeFocus: () => {
            setFocused(false);
        },
        isFocused: () => {
            return isFocused;
        },
        SetFocus: () => {
            setFocused(true);
        },
        myUniqueId: name
    }), [isFocused, name]);

    const handleBlur = () => {
        setTimeout(() => {
            nextFieldFocus(name);
            console.log("Next field is focused:");
        }, 0);
        console.log("Next field is focused:")
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            removeFocusFromAll(name); // Убрать фокус со всех элементов
            handleBlur();
        }
    };

    return (
        <Field name={name} validate={validate}>
            {({ field, form }) => (
                <div className="container">
                    <label className="text">{label}</label>
                    <textarea
                        className={`text border ${isFocused ? 'focused' : ''} multiline-input-text-input`}
                        placeholder={placeholder}
                        name={field.name}
                        value={field.value}
                        onChange={(e) => {
                            form.handleChange(e);  // передаем объект события
                        }}
                        onBlur={() => {
                            //setFocused(false);
                            //form.handleBlur(field.name);
                        }}
                        onFocus={() => {
                            removeFocusFromAll(name);
                            setFocused(true);
                        }}
                        onKeyDown={handleKeyDown}
                        rows="4"  // Установлено для 4 строк, измените это значение по своему усмотрению
                    />
                    {form.touched[field.name] && form.errors[field.name] && (
                        <div className="errorText">{form.errors[field.name]}</div>
                    )}
                </div>
            )}
        </Field>
    );
});

export default MultilineInput;