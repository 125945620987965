import { useState, useEffect } from 'react';
import axios from 'axios';

const useCountryByIP = () => {
    const [country, setCountry] = useState('');
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchCountry = async () => {
            try {
                const ipResponse = await axios.get('https://api.ipify.org?format=json');
                const ip = ipResponse.data.ip;

                const countryResponse = await axios.get(`https://ipinfo.io/${ip}/json`);
                setCountry(countryResponse.data.country);
            } catch (error) {
                setError(error);
            }
        };

        fetchCountry();
    }, []);

    return { country, error };
};

export default useCountryByIP;
