// validationSchema.js
import * as Yup from 'yup';
import appConfig from '../../static/json/appConfig.json';

export const makeValidationSchema = (userLanguage) => {
    const commonText = appConfig[userLanguage]["common"];

    return Yup.object({
        oldPassword: Yup.string()
            .min(8, commonText['Password must contain at least 8 characters'])
            .required(commonText['Required field']),
        newPassword: Yup.string()
            .min(8, commonText['Password must contain at least 8 characters'])
            .required(commonText['Required field']),
        cofirmNewPassword: Yup.string()
            .oneOf([Yup.ref('newPassword'), null], commonText['Passwords must match'])
            .min(8, commonText['Password must contain at least 8 characters'])
            .required(commonText['Required field'])
    });
};