import React from 'react';
import './Footer.css';
import '../../styles/inputStyles.css';
import PromptWithActionLink from '../common/PromptWithActionLink';

const Footer = ({ goToContactPage, goToTerms, goToConfirmation }) => {
    return (
        <footer className="footer-container text">
            <PromptWithActionLink
                prompt=""
                buttonText="Contact us"

                onLinkPress={() => {
                    console.log("Contacts pressed");
                    goToContactPage();
                }}
            />
            <PromptWithActionLink
                prompt=""
                buttonText="Terms of Use and Cancellation Policy"

                onLinkPress={() => {
                    console.log("Terms of Use and Cancellation Policy pressed");
                    goToTerms();
                }}
            />
            
            <PromptWithActionLink
                prompt=""
                buttonText="Demo payment"

                onLinkPress={() => {
                    console.log("Demo payment");
                    goToConfirmation();
                }}
            />
            
        </footer>
    );
}

export default Footer;
