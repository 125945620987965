// VerificationForm.js
import React, { useRef } from 'react'
import { Formik, Field } from 'formik';
import * as Yup from 'yup';

import SubmitButton from '../common/SubmitButton';
import VerificationSquare from './VerificationSquare'
import Container from '../common/Container';

import { useUser } from '../../context/UserContext';
import appConfig from '../../static/json/appConfig.json';

import "./VerificationForm.css"
import '../../styles/inputStyles.css';


const VerificationForm = ({ onSubmit, initialValues, onResend }) => {
    const validationSchema = Yup.object().shape({
        //square0: Yup.string().required().length(1),
        //square1: Yup.string().required().length(1),
        //square2: Yup.string().required().length(1),
        //square3: Yup.string().required().length(1),
    });

    const square2Ref = useRef();
    const square3Ref = useRef();
    const square4Ref = useRef();

    const { user, setUser } = useUser();
    const commonText = appConfig[user.language]["common"];

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {({ handleSubmit }) => (
                <>
                    <div className="verificationform-rectangle-container">
                        <Field name="square0" component={VerificationSquare} nextInput={square2Ref} />
                        <Field name="square1" component={VerificationSquare} nextInput={square3Ref} innerRef={square2Ref} />
                        <Field name="square2" component={VerificationSquare} nextInput={square4Ref} innerRef={square3Ref} />
                        <Field name="square3" component={VerificationSquare} innerRef={square4Ref} />
                    </div>
                    <div>
                        <div>
                            <div className="titleText verificationform-rectangle-container">
                                {commonText["If you don’t receive a code?"]}
                            </div>
                            <div className="verificationform-rectangle-container">
                                <button onClick={onResend} className="titleText  verificationform-ressend ">
                                    {commonText["RESEND"]}
                                </button>
                            </div>

                        </div>
                    </div>
                    <div>
                        <SubmitButton text={commonText["Continue"]} onSubmit={handleSubmit} />
                    </div>
                </>
            )}
        </Formik>
    );
};

export default VerificationForm;