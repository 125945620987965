import React, { useEffect, useState } from 'react';
import './Prediction.css';

import PredictionList from './PredictionList'; // Assuming this component is imported
import CalendarButtons from './CalendarButtons'; // Assuming this component is imported
import useButtonSelection from '../../hooks/useButtonSelection';

import appConfig from '../../static/json/appConfig.json';
import { useUser } from '../../context/UserContext';
import { useArticles } from '../../database/articlesManager';

const PredictionContent = ({ selectedZodiac }) => {
    console.log('PredictionContent is rendered');
    const { nextArticles } = useArticles();
    const { user } = useUser();
    const [articles, setArticles] = useState([]);

    const buttonsTop = appConfig[user.language]["buttonsTop"];
    const [selectedTop, handleSelectionChangeTop] = useButtonSelection(buttonsTop);

    useEffect(() => {
        loadArticles();
    }, [selectedTop, selectedZodiac, user]);

    const loadArticles = async () => {
        const recipient = selectedZodiac ? selectedZodiac : user.name;
        const articleType = selectedTop ? selectedTop.charAt(0).toUpperCase() + selectedTop.slice(1) : 'Day';

        console.log('PredictionContent Load articles:', recipient, articleType);
        const newArticles = await nextArticles(user, recipient, articleType, 0);
        console.log('Load articles:', newArticles.length);
        setArticles(newArticles);
    };

    const onLoadMore = async () => {
        const recipient = selectedZodiac ? selectedZodiac : user.name;
        const articleType = selectedTop ? selectedTop.charAt(0).toUpperCase() + selectedTop.slice(1) : 'Day';

        const newArticles = await nextArticles(user, recipient, articleType);
        setArticles(newArticles);
    };

    return (
        <div >
            <CalendarButtons
                buttons={buttonsTop}
                selected={selectedTop}
                onSelectionChange={handleSelectionChangeTop}
            />

            <div className="prediction-content-container prediction-content-flex1">
                <PredictionList
                    articles={articles}
                    onLoadMore={onLoadMore}
                    selectedZodiac={selectedZodiac}
                    selectedTop={selectedTop} 
                />
            </div>
        </div>
    );
};

export default PredictionContent;
