import { IS_TEST_MODE } from '../config/config';

export async function facebookService() {
    try {
        if (IS_TEST_MODE) {
            return new Promise(resolve => setTimeout(() => {
                resolve({
                    status: 200,
                    data: {
                        token: 'mock_access_token'
                    }
                });
            }, 1000));
        } else {
            const appId = 'YOUR_FACEBOOK_APP_ID'; // Замените на свой App ID
            const facebookLoginUrl = `https://www.facebook.com/v14.0/dialog/oauth?client_id=${appId}&redirect_uri=${encodeURIComponent(window.location.origin)}&scope=email,public_profile`;

            window.location.href = facebookLoginUrl;

            // Логика обработки результата аутентификации будет зависеть от реализации на стороне клиента и сервера
        }
    } catch (error) {
        return {
            status: 500,
            data: { error: error.message }
        };
    }
}