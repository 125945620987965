import axios from 'axios';
import { IS_TEST_MODE, BASE_URL, API_REGISTER_ENDPOINT } from '../config/config';

const getMockRegistrationData = () => {
    return {
        status: 201,
        data: {
            message: 'User registered successfully. Please check your email for the verification code.',
            user: {
                name: 'John Doe',
                email: 'johndoe@example.com',
                password: 'password123',
                agreeToTerms: true
            }
        }
    };
};

const sendRegistrationRequest = async (name, email, password, termConfirmed) => {
    const url = `${BASE_URL}${API_REGISTER_ENDPOINT}`;

    return axios.post(url, { name, email, password, termConfirmed }, {
        validateStatus: status => status < 500
    });
};

const processSuccessfulResponse = (response) => {
    if (!response.data || !response.data.data || typeof response.data.data !== 'object') {
        return handleErrorResponse('Invalid data format from server', 500);
    }

    return {
        status: response.status,
        data: response.data.data
    };
};

const handleErrorResponse = (errorMessage, errorStatus) => {
    console.warn('Registration error:', errorMessage);
    return { status: errorStatus, data: { error: errorMessage } };
};

const registerOnServer = async ({ name, email, password, termConfirmed }) => {
    if (IS_TEST_MODE) {
        return getMockRegistrationData();
    }

    try {
        const response = await sendRegistrationRequest(name, email, password, termConfirmed);

        if (response.status === 200 || response.status === 201) {
            return processSuccessfulResponse(response);
        } else {
            return handleErrorResponse(response.data.data.error, response.status);
        }
    } catch (error) {
        return handleErrorResponse(error.message || 'Unknown error occurred', error.response ? error.response.status : 500);
    }
};

export { registerOnServer };
