import React from 'react';

import appConfig from '../../static/json/appConfig.json';

import { makeValidationSchema } from './validationSchema';
import useFocusManagement from '../../hooks/useFocusManagement';
import { useScreenOffsetControl } from '../../hooks/useScreenOffsetControl';
import { getAdditionalPropsByName } from './getAdditionalPropsByName';

import CustomForm from '../common/CustomForm';
import { componentInstaller } from '../../utils/componentInstaller';
import { useUser } from '../../context/UserContext';

import './SignUp.css';

const SignUpForm = ({ onSubmit, goToTerms, initialValues, termsAccepted = false }) => {
    console.log('SignUpForm is rendered');

    const { user, setUser } = useUser();

    const commonText = appConfig[user.language]["common"];
    const submitText = user.registrated ? commonText["Select"] : commonText["Continue"];

    const validationSchema = makeValidationSchema(user.language);

    const fieldMetadataArray = appConfig[user.language]["signUpMetadataArray"];

    const refIdentifiers = fieldMetadataArray.map(item => item.name);

    const screenOffsets = Object.fromEntries(
        fieldMetadataArray.map(item => [item.name, item.screenOffset])
    );

    const [screenOffset, setFieldOffset, panResponder, updateContentHeight] = useScreenOffsetControl();

    const { setRef, removeFocusFromAll, nextFieldFocus } = useFocusManagement(refIdentifiers);

    const fieldsConfig = fieldMetadataArray.map(metadata => ({
        ...metadata,
        component: componentInstaller(metadata.component),
        additionalProps: getAdditionalPropsByName(metadata.name, termsAccepted, goToTerms)
    }));

    // Пример использования с дивами вместо View и без panResponder
    return (
        <div class="signup-form-container">
            <div
                onLayout={(event) => {
                    const height = event.currentTarget.offsetHeight;
                    updateContentHeight(height);
                }}
            >
                <CustomForm
                    fieldsConfig={fieldsConfig}
                    setRef={setRef}
                    removeFocusFromAll={removeFocusFromAll}
                    nextFieldFocus={nextFieldFocus}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                    submitText={submitText}
                />
            </div>
        </div>
    );
};

export default SignUpForm;