// src/hooks/useSetLanguageForUser.js
import { useUser } from '../context/UserContext';
import { getSystemLanguage } from '../services/getSystemLanguage';

const useSetLanguageForUser = () => {
    const { user, setUser } = useUser();

    const setLanguageForUser = () => {
        if (!user.language) {
            const language = getSystemLanguage();
            setUser({ ...user, language: language });
            console.log('System Language:', language);
        }
    };

    return setLanguageForUser;
};

export default useSetLanguageForUser;