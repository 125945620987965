import React from 'react';
import '../../styles/inputStyles.css';
import useBackHandler from '../../hooks/useBackHandler';
import SubmitButton from '../common/SubmitButton';



const ContactPage = ({ onBack }) => {

    useBackHandler(onBack);

    return (
        <div className="overlay bottom-div parent-centered-div">
            <div className="centered-div signin-centered-div">
                <h1 className="text" style={{ textAlign: 'center' }}>Contact Us</h1>
                <table className="contact-table text">
                    <tbody>
                        <tr>
                            <td width="40%"><strong>Contact Person:</strong></td>
                            <td>Kutomanov Hennadii</td>
                        </tr>
                        <tr>
                            <td><strong>Phone:</strong></td>
                            <td>+972-539337451</td>
                        </tr>
                        <tr>
                            <td><strong>Address:</strong></td>
                            <td>Israel, Petah-Tikva, Rav Kalisher str, 4bet-12</td>
                        </tr>
                        <tr>
                            <td><strong>Email:</strong></td>
                            <td>admin@astrology-ai.site</td>
                        </tr>
                    </tbody>
                </table>
                <SubmitButton
                    text= "Return"
                    onSubmit={() => {
                        onBack();
                    }}
                />
            </div>
        </div>
    );
};

export default ContactPage;
