import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import { Field } from 'formik';

import '../../styles/inputStyles.css'; // Предполагается, что стили inputStyles уже определены в CSS
import './FilteredPicker.css'; // CSS для listContainer и listItem

const StaticPicker = forwardRef((props, ref) => {
    console.log("StaticPicker is rendered")
    const { label, options, removeFocusFromAll, name, validate, onSelect, form, initialValue, nextFieldFocus, setRef } = props;
    const [isFocused, setFocused] = useState(false);
    const [selectedValue, setSelectedValue] = useState(initialValue);

    useEffect(() => {
        setSelectedValue(initialValue || '');
    }, [initialValue, options]);

    useImperativeHandle(setRef(name), () => ({
        removeFocus: () => {
            setFocused(false);
        },
        isFocused: () => {
            return isFocused;
        },
        setFocus: () => {
            setFocused(true);
        },
        myUniqueId: name
    }), [isFocused, name]);

    const handleBlur = () => {
        console.log("StaticPicker handleBlur called")
        setFocused(false);
        nextFieldFocus(name);
    };

    return (
        <Field name={name} validate={validate}>
            {({ field, form }) => (
                <div className="container" key={initialValue}>
                    <label className="text">{label}</label>
                    <div className={`border ${isFocused ? 'focused' : ''}`}>
                        <div
                            className="text static-picker-text-input"
                            onClick={() => setFocused(!isFocused)}
                        >
                            {selectedValue || 'Select...'}
                        </div>
                    </div>
                    {isFocused && (
                        <div className="filtered-picker-list-container">
                            {options.map((item, index) => (
                                <div
                                    key={index}
                                    className="filtered-picker-list-item"
                                    onClick={() => {
                                        form.setFieldValue(field.name, item.value);
                                        setSelectedValue(item.label);
                                        onSelect(item.label);
                                        handleBlur();
                                    }}
                                >
                                    {item.label}
                                </div>
                            ))}
                        </div>
                    )}
                    {form.touched[field.name] && form.errors[field.name] && (
                        <div className="errorText">{form.errors[field.name]}</div>
                    )}
                </div>
            )}
        </Field>
    );
});

export default StaticPicker;
