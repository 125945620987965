// articlesModel.js
import initializeDatabase from './databaseSetup';

const addArticles = async (articles) => {
    const db = await initializeDatabase();
    const tx = db.transaction('articles', 'readwrite');

    for (let article of articles) {
        const existingArticle = await tx.store.get(article.id);
        if (existingArticle) {
            await tx.store.put(article);
        } else {
            await tx.store.add(article);
        }
    }

    await tx.done;
};

const getArticles = async ({ recipient, articleType, startNumber, endNumber }) => {
    const db = await initializeDatabase();
    const store = db.transaction('articles', 'readonly').objectStore('articles');
    const index = store.index('publication_date');
    const range = IDBKeyRange.bound(startNumber, endNumber);
    const articles = await index.getAll(range);
    return articles.filter(
        (article) => article.recipient === recipient && article.article_type === articleType
    );
};

//const getAllArticles = async ({ recipient, articleType }) => {
//    const db = await initializeDatabase();
//    const store = db.transaction('articles', 'readonly').objectStore('articles');
//    const articles = await store.getAll();
//    return articles.filter(
//        (article) => article.recipient === recipient && article.article_type === articleType
//    );
//};

const getAllArticles = async ({ recipient, articleType, language }) => {
    const db = await initializeDatabase();
    const store = db.transaction('articles', 'readonly').objectStore('articles');
    const articles = await store.getAll();
    return articles.filter(
        (article) =>
            article.recipient === recipient &&
            article.article_type === articleType &&
            article.language === language
    );
};


const deleteArticles = async (ids) => {
    const db = await initializeDatabase();
    const tx = db.transaction('articles', 'readwrite');
    for (let id of ids) {
        await tx.store.delete(id);
    }
    await tx.done;
};

export { addArticles, getAllArticles, deleteArticles };