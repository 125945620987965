import React, { useState, useEffect, useCallback } from 'react';

import GreetingList from './GreetingList';
import '../../styles/inputStyles.css';
import './GreetingForm.css';

import { useUser } from '../../context/UserContext';
import appConfig from '../../static/json/appConfig.json';
import useSetLanguageForUser from '../../hooks/useSetLanguageForUser';

const GreetingForm = ({ goToSignUp, goToSignIn }) => {
    const { user, setUser } = useUser();
    const setLanguageForUser = useSetLanguageForUser();

    const commonText = appConfig[user.language]["common"];
    const greetingPage = appConfig[user.language]["GreetingPage"];


    const images = {
        "Welcome": require('../../static/image/Sky1.webp'),
        "Neuronet": require('../../static/image/Sky2.webp'),
        "Zodiac": require('../../static/image/Sky3.webp'),
        "Individual": require('../../static/image/Sky4.webp'),
        "Availability": require('../../static/image/Sky5.webp'),
        "Daily": require('../../static/image/Sky6.webp'),
        "Astro Rating": require('../../static/image/Sky7.webp'),
    };

    useEffect(() => {
        setLanguageForUser();
    }, [setLanguageForUser]);

    return (
        <div className="prediction-overlay bottom-div parent-centered-div">
            <div className="centered-div ">
                <div >
                    <div className="greeting-form-content-container greeting-form-content-flex1">
                        <GreetingList
                            articles={greetingPage}
                            images={images}
                            goToSignUp={goToSignUp}
                            goToSignIn={goToSignIn}
                        />
                    </div>
                </div>

            </div>
        </div>


    );
};


export default GreetingForm;