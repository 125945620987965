// Переназначение экспорта с использованием import вместо require для поддержки стандартных сценариев 
// веб - разработки.
export const icons = {
    calendarDay: new URL('../../static/image/noun-day-247253.png', import.meta.url).href,
    calendarWeek: new URL('../../static/image/noun-week-247256.png', import.meta.url).href,
    calendarMonth: new URL('../../static/image/noun-month-247254.png', import.meta.url).href,
    Person: new URL('../../static/image/noun-person-6759078.png', import.meta.url).href,
    Zodiac: new URL('../../static/image/noun-zodiac-6657986.png', import.meta.url).href,
    Menu: new URL('../../static/image/noun-menu-6828990.png', import.meta.url).href,

};

export default icons;