import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import { Field } from 'formik';

import '../../styles/inputStyles.css'; // Предполагается, что стили inputStyles уже определены в CSS
import './FilteredPicker.css'; // CSS для listContainer и listItem

const FilteredPicker = forwardRef((props, ref) => {
    console.log("FilteredPicker is rendered")
    const { label, options, removeFocusFromAll, name, validate, onSelect, form, placeholder, initialValue, nextFieldFocus, setRef } = props;
    const [isFocused, setFocused] = useState(false);
    const [filteredOptions, setFilteredOptions] = useState(options);

    /*    console.log("FilteredPicker - initialValue:", initialValue);*/
    const [inputValue, setInputValue] = useState(initialValue);

    useEffect(() => {
        setInputValue(initialValue || '');
    }, [initialValue, options]);
    /*    console.log("FilteredPicker - inputValue:", inputValue);*/

    const inputRef = useRef(null);

    useImperativeHandle(setRef(name), () => ({
        removeFocus: () => {
            setFocused(false);
        },
        isFocused: () => {
            return isFocused;
        },
        SetFocus: () => {
            setFocused(true);
        },
        myUniqueId: name
    }), [isFocused, name]);

    const handleBlur = () => {
        setFocused(false);
        nextFieldFocus(name);
    };


    useEffect(() => {
        if (options.length === 0) {
            setFilteredOptions([]);
            return;
        }
        if (!inputValue) {
            setFilteredOptions(options);
        } else {
            setFilteredOptions(options.filter(option =>
                option && option.label && option.label.toLowerCase().startsWith(inputValue.toLowerCase())
            ));
        }
    }, [options, inputValue]);

    const filterOptions = (text) => {
        setInputValue(text);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            form.setFieldValue(name, inputValue); // Установить значение поля формы на текущее значение ввода
            removeFocusFromAll(name); // Убрать фокус со всех элементов
            onSelect(inputValue); // Вызов функции onSelect с текущим значением ввода
            setTimeout(() => handleBlur(), 0);
        }
    };

    return (
        <Field name={name} validate={validate}>
            {({ field, form }) => (
                <div className="container" key={initialValue}>
                    <label className="text">{label}</label>
                    <div className={`border ${isFocused ? 'focused' : ''}`}>
                        <div className="filtered-picker-text-input-container">
                            <input
                                ref={inputRef}
                                className="text filtered-picker-text-input"
                                value={inputValue}
                                onChange={(e) => filterOptions(e.target.value)}
                                onKeyDown={handleKeyDown} // Добавить обработчик события здесь
                                onFocus={() => {
                                    removeFocusFromAll(name);
                                    setFocused(true);
                                }}
                                placeholder={placeholder}
                            />
                        </div>
                    </div>
                    {isFocused && (
                        <div className="filtered-picker-list-container">
                            {filteredOptions.map((item, index) => (
                                <div
                                    key={index}
                                    className="filtered-picker-list-item"
                                    onClick={() => {
                                        form.setFieldValue(field.name, item.value);
                                        console.log('Выбранный элемент:', item.label, item.value);
                                        console.log('Значение поля формы установлено:', form.values[name]);
                                        setInputValue(item.label);
                                        onSelect(item.label);
                                        setTimeout(() => handleBlur(), 0);
                                    }}
                                >
                                    {item.label}
                                </div>
                            ))}
                        </div>
                    )}
                    {form.touched[field.name] && form.errors[field.name] && (
                        <div className="errorText">{form.errors[field.name]}</div>
                    )}
                </div>
            )}
        </Field>
    );
});

export default FilteredPicker;