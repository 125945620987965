import React, { useState, useEffect } from "react";
import './Prediction.css';

import PredictionContent from './PredictionContent';
import CalendarButtons from './CalendarButtons';
import Zodiac from './Zodiac';
import Menu from './Menu'

import { useUser } from '../../context/UserContext';

import useButtonSelection from '../../hooks/useButtonSelection';
import appConfig from '../../static/json/appConfig.json';

const Prediction = ({ goToGreetingForm }) => {
    console.log('Prediction is rendered');

    const { user, setUser } = useUser();
    const [isInsideMenu, setIsInsideMenu] = useState(false);

    const buttonsBottom = appConfig[user.language]["buttonsBottom"];

    const [selectedBottom, handleSelectionChangeBottom] = useButtonSelection(buttonsBottom);
    const [selectedZodiac, setSelectedZodiac] = useState(null);

    const handleZodiacSelection = (zodiacName) => {
        setSelectedZodiac(zodiacName);
        handleSelectionChangeBottom("");
        setUser(prevUser => ({
            ...prevUser,
            zodiacSign: zodiacName
        }));
    };

    const onMenuBack = () => {
        setIsInsideMenu(false);
        handleSelectionChangeBottom("Person");
    };

    //useEffect(() => {
    //    setIsInsideMenu(false);
    //});

    return (
        <div className="prediction-overlay bottom-div parent-centered-div">
            <div className="centered-div ">
                {selectedBottom === 'Person' && <PredictionContent />}
                {selectedBottom === '' && <PredictionContent selectedZodiac={selectedZodiac} />}
                {selectedBottom === 'Zodiac' && <Zodiac onZodiacSelected={handleZodiacSelection} />}
                {selectedBottom === 'Menu' && <Menu onBack={onMenuBack} goToGreetingForm={goToGreetingForm} isInsideMenu={isInsideMenu} setIsInsideMenu={setIsInsideMenu} />}

                <div className="prediction-bottom-button-container">
                    <CalendarButtons
                        buttons={buttonsBottom}
                        selected={selectedBottom}
                        onSelectionChange={handleSelectionChangeBottom}
                        setIsInsideMenu ={setIsInsideMenu}
                    />
                </div>

            </div>
        </div>

    );
};

export default Prediction;
