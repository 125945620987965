import React from 'react';
import './Prediction.css';
import '../../styles/colors.css';
import icons from './icons';

const CalendarButtons = ({ buttons, selected, onSelectionChange, setIsInsideMenu }) => {
    const handleButtonPress = (newSelection) => {
        if (setIsInsideMenu) {
            setIsInsideMenu(false);
        }
        onSelectionChange(null);
        onSelectionChange(newSelection);
    };

    return (
        <div className="calendar-button-container">
            {buttons.map((button) => (

                <button
                    key={button.label}
                    className={`calendar-button-item ${selected === button.name ? "selected" : ""}`}
                    onClick={() => handleButtonPress(button.name)}
                >
                    <img
                        src={icons[button.iconName]}
                        alt={button.label}
                        className={`calendar-button-icon ${selected === button.name ? 'selected' : ''}`}
                    />
                    <span className={`calendar-button-label ${selected === button.name ? 'selected' : ''}`}>{button.label}</span>
                </button>
            ))}

           
        </div>
    );
};


export default CalendarButtons;